import React from 'react'

export default class LeadActivityWidget extends React.Component {
  state = {
    count: 0,
    data: [],
    end: 0,
    events: [],
    next: 0,
    perpage: 10,
    previous: 0,
    start: 1,
    total_pages: 0,
    loading: false,
  }
  componentDidMount() {
    if (this.props.lead_id) {
      console.log('The lead id we haev is in props ', this.props)
      this.fetchLeadActivity(this.props.lead_id, 0, 0)
    }
  }
  componentDidUpdate(prevProps) {
    console.log(
      'The change in lead id are ',
      this.props?.lead_id,
      this.prevProps?.lead_id
    )
    if (this.props.lead_id != prevProps.lead_id) {
      if (this.props.lead_id) this.fetchLeadActivity(this.props.lead_id, 0, 0)
    }
  }

  displayEvents = () => {
    return this.state.events?.map((ev, ind) => (
      <div className="timeline-item" key={'lveve_' + ind}>
        <div className="timeline-badge" style={{ padding: '2px' }}>
          <div className="timeline-icon" style={{ padding: '2px !important' }}>
            <img
              src={`${window.location.origin}/backend_spa/index.php/User/get_users_avatar/${ev?.extension}`}
              height="45px"
              width="46px"
              className=" img img-circle"
            />
          </div>
        </div>
        <div className="timeline-body">
          <div className="timeline-body-arrow"> </div>
          <div className="timeline-body-head">
            <div className="timeline-body-head-caption">
              <i
                className="fa fa-plus font-green-haze"
                style={{ marginRight: '4px' }}
              ></i>
              <a
                href="javascript:;"
                className="timeline-body-title font-blue-madison"
              ></a>
              <span className="timeline-body-time font-grey-cascade">
                ev.sch_time + &nbsp;<b>Lead Added via API</b>
              </span>
            </div>
          </div>
          <div className="timeline-body-content">
            <span className="font-grey-cascade"> </span>
          </div>
        </div>
      </div>
    ))
  }
  displayActivity = () => {
    return this.state.data?.map((dat) => {
      var name = ''
      var date = ''
      var notes_or_call_duration = ''
      var icon = ''
      var audio_cntrl = ''
      let dispo_status_name = ''
      if (dat.type == 'note') {
        name = dat.full_name + ' (' + dat?.agent + ')'
        date = ' Added Note at ' + dat?.crt_on
        notes_or_call_duration = <>{dat?.call_notes}</>
        icon = 'icon-notebook'
      } else if (dat.type == 'call_log') {
        name = dat.full_name + ' (' + dat.agent + ')'
        dispo_status_name =
          dat.sys_status_name !== undefined && dat.sys_status_name !== null
            ? dat.sys_status_name.length > 0
              ? ' (' + dat.sys_status_name + ')'
              : dat.cmp_status_name.length > 0
              ? ' (' + dat.cmp_status_name + ')'
              : ''
            : ''

        let cb_date = null
        //check for callback and show date if there's one
        try {
          if (dat.callback_time !== undefined) {
            var edit_history = ''
            if (dat.history !== undefined) {
              edit_history = ' changed from ' + dat.history
            }
            cb_date = (
              <p>
                scheduled on {dat.callback_time} {edit_history}
              </p>
            )
          }
        } catch (e) {}
        date = (
          <>
            Called {dat?.number_dialed} on {dat?.start_time}{' '}
            <b>
              {dat?.call_duration} {dat?.dispo_status} {dispo_status_name}
            </b>
            {cb_date}
          </>
        )
        //
        notes_or_call_duration = (
          <>
            {dat.call_notes}
            {dat?.EMAIL?.map((emaily) => (
              <a
                href="javascript:;"
                onClick={(e) => {
                  window.view_email(emaily?.id)
                }}
                className="timeline-body-title font-blue-madison"
              >
                Sent Email
              </a>
            ))}
            {dat?.SMS?.map((smsy) => (
              <>
                <br /> {smsy.content}
              </>
            ))}
          </>
        )

        if (
          dat.hasRecording == 'YES' &&
          (this.props?.user_role != 'agent' ||
            this.props?.system_settings?.can_play_recordings == 'Yes')
        )
          audio_cntrl = (
            <>
              <br />
              <audio controls={true}>
                <source
                  src={`https://${window.tenant_url}//app/call_recordings/Dolphin_download.php?date=${dat?.crt_on_rec}&id=${dat?.pbx_sip_call_id}`}
                ></source>
                <source
                  src={`https://${window.tenant_url}//app/call_recordings/Dolphin_download.php?date=${dat?.crt_on_rec}&id=${dat?.fpbx_uuid}`}
                ></source>
              </audio>
            </>
          )

        icon = 'icon-call-out'
      } else if (dat.type == 'email') {
        name = dat.full_name + ' (' + dat.agent + ')'
        date = ' Sent Email on ' + dat.crt_on
        notes_or_call_duration = null //dat.email_subject;
        icon = 'icon-envelope'
        audio_cntrl = (
          <a
            href="javascript:;"
            onClick={(e) => {
              window.view_email(dat?.send_email_id)
            }}
            className="timeline-body-title font-blue-madison"
          >
            Sent Email
          </a>
        )
      } else if (dat.type == 'text') {
        name = dat.full_name + ' (' + dat.agent + ')'
        date = 'Sent SMS on ' + dat.crt_on
        notes_or_call_duration = <></>
        icon = 'icon-screen-smartphone'
      } else if (dat.type == 'converted_to_contact') {
        name = dat.full_name + ' (' + dat.agent + ')'
        date = 'Converted to Contact on ' + dat.crt_on
        notes_or_call_duration = <></>
        icon = 'icon-screen-smartphone'
      } else if (dat.type == 'switched_list') {
        name = dat.full_name + ' (' + dat.agent + ')'
        date = dat.note
        notes_or_call_duration = <></>
        icon = 'icon-screen-smartphone'
      } else if (dat.type == 'switched_assigned_user') {
        name = dat.full_name + ' (' + dat.agent + ')'
        date = dat.note
        notes_or_call_duration = <></>
        icon = 'icon-screen-smartphone'
      } else if (dat.type == 'switched_lead_status') {
        name = dat.full_name + ' (' + dat.agent + ')'
        date = dat.note
        notes_or_call_duration = <></>
        icon = 'icon-screen-smartphone'
      }

      return (
        <div className="timeline-item" key={dat?.uniqueid}>
          <div className="timeline-badge" style={{ padding: '2px' }}>
            <div
              className="timeline-icon"
              style={{ padding: '2px !important' }}
            >
              <img
                src={`${window.location.origin}/backend_spa/index.php/User/get_users_avatar/${dat.agent}`}
                height="45px"
                width="46px"
                className=" img img-circle"
              />
            </div>
          </div>
          <div className="timeline-body">
            <div className="timeline-body-arrow"> </div>
            <div className="timeline-body-head">
              <div className="timeline-body-head-caption">
                <i
                  className={`${icon} font-green-haze`}
                  style={{ marginRight: '4px' }}
                ></i>
                <a
                  href="javascript:;"
                  className="timeline-body-title font-blue-madison"
                >
                  {name}
                </a>
                <span className="timeline-body-time font-grey-cascade">
                  {date}
                </span>
              </div>
            </div>
            <div className="timeline-body-content">
              <span className="font-grey-cascade" style={{ display: 'block' }}>
                {notes_or_call_duration}
              </span>
              {audio_cntrl}
            </div>
          </div>
        </div>
      )
    })
  }

  //   refresh_callNotes = async (el, lead, page, perpage) => {
  //     var btn_next = 'btn_act_next'
  //     var btn_prev = 'btn_act_prev'
  //     if (!el) {
  //       el = '#qlv_leadNotes'
  //       btn_next = 'qlv_' + btn_next
  //       btn_prev = 'qlv_' + btn_prev
  //     }
  //     if (!page) {
  //       page = 0
  //     }
  //     if (!perpage) perpage = 0
  //     if (!lead || lead === undefined || lead === null) lead = window.lead_dialed

  //     //modify the called method now
  //     const response = await this.fetchLeadActivity(lead, page, perpage)

  //     window.$(el).html('')
  //     console.warn(response)
  //     window
  //       .$('#record_count')
  //       .html(
  //         'Showing ' +
  //           response.start +
  //           ' to ' +
  //           response.end +
  //           ' out of ' +
  //           response.count +
  //           ' records'
  //       )

  //     var events = response.events
  //     for (var ei = 0; ei < events.length; ei++) {
  //       var activity =
  //         '<div className="timeline-item">' +
  //         '<div className="timeline-badge" style="padding: 2px;">' +
  //         '<div className="timeline-icon" style="padding: 2px !important;">' +
  //         '<img src="' +
  //         window.location.origin +
  //         '/backend_spa/index.php/User/get_users_avatar/' +
  //         events[ei].extension +
  //         '" height="45px" width="46px" className=" img img-circle">' +
  //         '</div>' +
  //         '</div>' +
  //         '<div className="timeline-body">' +
  //         '<div className="timeline-body-arrow"> </div>' +
  //         '<div className="timeline-body-head">' +
  //         '<div className="timeline-body-head-caption">' +
  //         '<i className="fa fa-plus font-green-haze" style="margin-right: 4px;"></i>' +
  //         '<a href="javascript:;" className="timeline-body-title font-blue-madison"></a>' +
  //         '<span className="timeline-body-time font-grey-cascade">' +
  //         events[ei].sch_time +
  //         '&nbsp;<b>Lead Added via API</b></span>' +
  //         '</div>' +
  //         '</div>' +
  //         '<div className="timeline-body-content">' +
  //         '<span className="font-grey-cascade">  </span>' +
  //         '</div>' +
  //         '</div>' +
  //         '</div>'
  //       window.$(el).append(activity)
  //     }
  //     var data = response.data
  //     for (var i = 0; i < data.length; i++) {
  //       var name = ''
  //       var date = ''
  //       var notes_or_call_duration = ''
  //       var icon = ''
  //       var audio_cntrl = ''
  //       if (data[i].type == 'note') {
  //         name = data[i].full_name + ' (' + data[i].agent + ')'
  //         date = ' Added Note at ' + data[i].crt_on
  //         notes_or_call_duration = data[i].call_notes
  //         icon = 'icon-notebook'
  //       } else if (data[i].type == 'call_log') {
  //         name = data[i].full_name + ' (' + data[i].agent + ')'
  //         window.dispo_status_name =
  //           data[i].sys_status_name !== undefined &&
  //           data[i].sys_status_name !== null
  //             ? data[i].sys_status_name.length > 0
  //               ? ' (' + data[i].sys_status_name + ')'
  //               : data[i].cmp_status_name.length > 0
  //               ? ' (' + data[i].cmp_status_name + ')'
  //               : ''
  //             : ''
  //         date =
  //           ' Called ' +
  //           data[i].number_dialed +
  //           ' on ' +
  //           data[i].start_time +
  //           ' <b>' +
  //           data[i].call_duration +
  //           ' ' +
  //           data[i].dispo_status +
  //           window.dispo_status_name +
  //           '</b>'
  //         //check for callback and show date if there's one
  //         try {
  //           if (data[i].callback_time !== undefined) {
  //             var edit_history = ''
  //             if (data[i].history !== undefined) {
  //               edit_history = ' changed from ' + data[i].history
  //             }
  //             date +=
  //               ' <p>scheduled on ' +
  //               data[i].callback_time +
  //               ' ' +
  //               edit_history +
  //               '</p>'
  //           }
  //         } catch (e) {}
  //         //
  //         notes_or_call_duration = data[i].call_notes
  //         //traverse for emails to list them here
  //         for (var ei = 0; ei < data[i].EMAIL.length; ei++) {
  //           notes_or_call_duration +=
  //             ' <a  href="javascript:view_email(' +
  //             data[i].EMAIL[ei].id +
  //             ')" className="timeline-body-title font-blue-madison">Sent Email</a>'
  //         }
  //         ////////////////////////////////////////////////////////
  //         //traverse for  sms to list them here
  //         for (var si = 0; si < data[i].SMS.length; si++) {
  //           notes_or_call_duration += '<br />' + data[i].SMS[si].content
  //         }
  //         ////////////////////////////////////////////////////////
  //         if (data[i].hasRecording == 'YES')
  //           audio_cntrl =
  //             '<br /><audio controls=true >' +
  //             '<source src="https://' +
  //             window.tenant_url +
  //             '/app/call_recordings/Dolphin_download.php?date=' +
  //             data[i].crt_on_rec +
  //             '&id=' +
  //             data[i].pbx_sip_call_id +
  //             '"></source>' +
  //             '<source src="https://' +
  //             window.tenant_url +
  //             '/app/call_recordings/Dolphin_download.php?date=' +
  //             data[i].crt_on_rec +
  //             '&id=' +
  //             data[i].fpbx_uuid +
  //             '"></source>' +
  //             '</audio>'
  //         icon = 'icon-call-out'
  //       } else if (data[i].type == 'email') {
  //         name = data[i].full_name + ' (' + data[i].agent + ')'
  //         date = ' Sent Email on ' + data[i].crt_on
  //         notes_or_call_duration = '' //data[i].email_subject;
  //         icon = 'icon-envelope'
  //         audio_cntrl =
  //           ' <a  href="javascript:view_email(' +
  //           data[i].send_email_id +
  //           ')" className="timeline-body-title font-blue-madison">Sent Email</a>'
  //       } else if (data[i].type == 'text') {
  //         name = data[i].full_name + ' (' + data[i].agent + ')'
  //         date = 'Sent SMS on ' + data[i].crt_on
  //         notes_or_call_duration = ''
  //         icon = 'icon-screen-smartphone'
  //       } else if (data[i].type == 'converted_to_contact') {
  //         name = data[i].full_name + ' (' + data[i].agent + ')'
  //         date = 'Converted to Contact on ' + data[i].crt_on
  //         notes_or_call_duration = ''
  //         icon = 'icon-screen-smartphone'
  //       } else if (data[i].type == 'switched_list') {
  //         name = data[i].full_name + ' (' + data[i].agent + ')'
  //         date = data[i].note
  //         notes_or_call_duration = ''
  //         icon = 'icon-screen-smartphone'
  //       } else if (data[i].type == 'switched_assigned_user') {
  //         name = data[i].full_name + ' (' + data[i].agent + ')'
  //         date = data[i].note
  //         notes_or_call_duration = ''
  //         icon = 'icon-screen-smartphone'
  //       } else if (data[i].type == 'switched_lead_status') {
  //         name = data[i].full_name + ' (' + data[i].agent + ')'
  //         date = data[i].note
  //         notes_or_call_duration = ''
  //         icon = 'icon-screen-smartphone'
  //       }
  //       var activity =
  //         '<div className="timeline-item">' +
  //         '<div className="timeline-badge" style="padding: 2px;">' +
  //         '<div className="timeline-icon" style="padding: 2px !important;">' +
  //         '<img src="' +
  //         window.location.origin +
  //         '/backend_spa/index.php/User/get_users_avatar/' +
  //         data[i].agent +
  //         '" height="45px" width="46px" className=" img img-circle">' +
  //         '</div>' +
  //         '</div>' +
  //         '<div className="timeline-body">' +
  //         '<div className="timeline-body-arrow"> </div>' +
  //         '<div className="timeline-body-head">' +
  //         '<div className="timeline-body-head-caption">' +
  //         '<i className="' +
  //         icon +
  //         ' font-green-haze" style="margin-right: 4px;"></i>' +
  //         '<a href="javascript:;" className="timeline-body-title font-blue-madison">' +
  //         name +
  //         '</a>' +
  //         '<span className="timeline-body-time font-grey-cascade">' +
  //         date +
  //         '</span>' +
  //         '</div>' +
  //         '</div>' +
  //         '<div className="timeline-body-content">' +
  //         '<span className="font-grey-cascade" style="display:block;"> ' +
  //         notes_or_call_duration +
  //         ' </span>' +
  //         audio_cntrl +
  //         '</div>' +
  //         '</div>' +
  //         '</div>'
  //       window.$(el).append(activity)
  //     }
  //     //
  //   }

  fetchLeadActivity = async (lead_id, page, rpp) => {
    if (this.state.loading) return
    this.setState({ loading: true })
    try {
      const lead_activity_url = `${window.lead_activity_detail_url}/${lead_id}?page=${page}&perpage=${rpp}`

      //   console.log('The request was ', lead_activity_url)
      const res = await fetch(lead_activity_url, {
        method: 'GET',
        credentials: 'include',
      })
      //   console.log('The request was1 8', res)
      const resj = await res.json()
      console.log('The request was1 8', resj)
      const safeData = Array.isArray(resj?.data) ? [...resj.data] : []
      const safeEvents = Array.isArray(resj?.events) ? [...resj.events] : []
      this.setState({
        count: resj?.count ?? 0,
        data: safeData,
        end: resj?.end ?? 0,
        events: safeEvents,
        next: resj?.next ?? 0,
        perpage: resj?.perpage ?? 10,
        previous: resj?.previous ?? 0,
        start: resj?.start ?? 1,
        total_pages: resj?.total_pages ?? 0,
      })
      //   console.log('The request was1 ', resj)
      //   return resj
    } catch (e) {
      console.log(e, 'The request was ')
      //   return null
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <>
        {' '}
        <div className="portlet light portlet-fit ">
          <div className="portlet-body">
            <div className="timeline">
              <div className="timeline-item">
                <div className="timeline-badge">
                  <div className="timeline-icon">
                    <i className="icon-user font-green-jungle"></i>
                  </div>
                </div>
                <div className="timeline-body">
                  <div className="timeline-body-arrow"> </div>
                  <div className="timeline-body-head">
                    <form
                      id="form_postnote"
                      onSubmit={this.props.handleSubmitNote}
                      method="post"
                    >
                      <input type="hidden" name="action" value="add_note" />
                      <input
                        type="hidden"
                        name="lead_id"
                        value={this.props.lead_id}
                      />
                      <textarea
                        name="note"
                        rows="4"
                        style={{ width: '100%' }}
                      ></textarea>
                      <button
                        type="submit"
                        className="btn btn-default pull-right"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <span>
                {this.displayEvents()}
                {this.displayActivity()}
              </span>
            </div>
            <div className="clearfix">
              <div className="pull-left">
                {'Showing ' +
                  this.state.start +
                  ' to ' +
                  this.state.end +
                  ' out of ' +
                  this.state.count +
                  ' records'}
              </div>
              <div className="btn-toolbar pull-right">
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-default"
                    disabled={!this.state.previous}
                    onClick={(e) => {
                      this.fetchLeadActivity(
                        this.props?.lead_id,
                        this.state.previous,
                        0
                      )
                    }}
                  >
                    Prev
                  </button>
                </div>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-default"
                    disabled={!this.state.next}
                    onClick={(e) => {
                      this.fetchLeadActivity(
                        this.props?.lead_id,
                        this.state.next,
                        0
                      )
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
