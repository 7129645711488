import React, { Component } from 'react'

import HeadTitle from './HeadTitle'
import HighChart from './Bricks/HighChart'
class Dashboard_agent extends Component {
  dt_callbacks = null

  state = {
    isAgent: true,
    categories: [1, 2, 3],
    series: [0, 0, 0],
    statuses: [],
    calls_today: 0,
    calls_in_7_days: 0,
    call_time: 0,
    total_sales: 0,
    callbacks: [],
    show_alert: false,
  }
  periodic_ajax_call = null
  componentDidMount() {
    //alert("agent");
    window.resizeHandle()
    if (this.props.role === 'agent') {
      this.agentdashboardstats() //call it first then start interval so it'll keep updating stats every 30 sec
      this.periodic_ajax_call = setInterval(this.agentdashboardstats, 300000)

      //load callbacks list
      this.dt_callbacks = window.dttable(
        'db_leadevents',
        this.props.baseUrl + '/index.php/LeadEvents/dt_ListLeadEvents/callback',
        [
          {
            orderable: false,
            targets: [1, 2, 3],
          },
          {
            visible: false,
            targets: [0],
          },
        ],
        'body',
        [0, 'asc']
      )
      ////////////////////////
    }
    console.log('called')
    this.disp_alert(this.props.show_alert)
  }
  componentWillUnmount() {
    clearInterval(this.periodic_ajax_call)
  }
  agentdashboardstats = () => {
    window.App.blockUI()
    //fetch dashboard in case its agent otherwise fetch managers dashboard
    fetch(this.props.baseUrl + '/index.php/Dashboard', {
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {
        console.log(data)
        this.setState({
          calls_today: data.CallCount,
          calls_in_7_days: data.CallCount7days,
          call_time: data.CallTime,
          total_sales: data.salesToday,
          callbacks: data.Callbacks,
        })
        /***lets fetch the thing for charts */
        fetch(this.props.baseUrl + '/index.php/Ajax/totalCallsIn7Days', {
          credentials: 'include',
        })
          .then((blob) => blob.json())
          .then((data) => {
            console.log(data)
            let series = []
            let categories = []
            for (var i = 0; i < data.length; i++) {
              series.push(data[i].calls)
              categories.push(data[i].date)
            }
            this.setState({ series: series, categories: categories })
          })
          .catch((err) => {})
        /***************************************** */
        window.App.unblockUI()
      })
      .catch((err) => {
        window.App.unblockUI()
      })
    ///////////////////////////////////////////////////////////////////////////
  }
  componentWillReceiveProps(nP) {
    this.disp_alert(nP.show_alert)
  }
  disp_alert = (alert) => {
    if (alert) {
      window.displayAlert(
        '#alert_container',
        window.global_alert_msg,
        'success'
      )
      window.global_alert_msg = ''
    }
  }
  render() {
    return (
      <div className="page-container">
        <div className="page-content-wrapper">
          <HeadTitle></HeadTitle>
          <div className="page-content">
            <div className="container">
              <span id="alert_container"></span>
              <div className="page-content-inner">
                <div className="row widget-row">
                  <div className="col-md-3">
                    <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                      <h4 className="widget-thumb-heading">My Calls Today</h4>
                      <div className="widget-thumb-wrap">
                        {/*<i className="widget-thumb-icon bg-green icon-call-out"></i>*/}
                        <span
                          class="material-icons widget-thumb-icon bg-green"
                          style={{ fontSize: '28px' }}
                        >
                          call
                        </span>
                        <div className="widget-thumb-body">
                          <span className="widget-thumb-subtitle"></span>
                          <span
                            className="widget-thumb-body-stat"
                            data-counter="counterup"
                            data-value={this.state.calls_today}
                          >
                            {this.state.calls_today}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                      <h4 className="widget-thumb-heading">
                        My Calls in Last 7 Days
                      </h4>
                      <div className="widget-thumb-wrap">
                        {/*<i className="widget-thumb-icon bg-red icon-call-in"></i>*/}
                        <span
                          class="material-icons widget-thumb-icon bg-green-meadow"
                          style={{ fontSize: '28px' }}
                        >
                          call
                        </span>
                        <div className="widget-thumb-body">
                          <span className="widget-thumb-subtitle"></span>
                          <span
                            className="widget-thumb-body-stat"
                            data-counter="counterup"
                            data-value={this.state.calls_in_7_days}
                          >
                            {this.state.calls_in_7_days}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                      <h4 className="widget-thumb-heading">
                        Talk Time in Last 7 Days
                      </h4>
                      <div className="widget-thumb-wrap">
                        {/*<i className="widget-thumb-icon bg-purple icon-call-end"></i>*/}
                        <span
                          class="material-icons widget-thumb-icon bg-purple"
                          style={{ fontSize: '28px' }}
                        >
                          timelapse
                        </span>
                        <div className="widget-thumb-body">
                          <span className="widget-thumb-subtitle"></span>
                          <span
                            className="widget-thumb-body-stat"
                            data-counter="counterup"
                            data-value={this.state.call_time}
                          >
                            {this.state.call_time}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 ">
                      <h4 className="widget-thumb-heading">Total Sales</h4>
                      <div className="widget-thumb-wrap">
                        {/*<i className="widget-thumb-icon bg-blue icon-login"></i>*/}
                        <span
                          class="material-icons widget-thumb-icon  bg-blue"
                          style={{ fontSize: '28px' }}
                        >
                          trending_up
                        </span>
                        <div className="widget-thumb-body">
                          <span className="widget-thumb-subtitle"></span>
                          <span
                            className="widget-thumb-body-stat"
                            data-counter="counterup"
                            data-value={this.state.total_sales}
                          >
                            {this.state.total_sales}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="portlet light ">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className="icon-bar-chart font-green-jungle"></i>
                              <span className="caption-subject bold uppercase font-green-jungle">
                                Total Calls&nbsp;
                              </span>
                              <span className="caption-helper">
                                in last 7 days
                              </span>
                            </div>
                            <div className="tools"></div>
                          </div>
                          <div className="portlet-body">
                            <HighChart
                              categories={this.state.categories}
                              series={this.state.series}
                            ></HighChart>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="portlet light portlet-fit ">
                          <div className="portlet-title">
                            <div className="caption">
                              <i className=" icon-layers font-green"></i>
                              <span className="caption-subject font-green bold uppercase">
                                My Callbacks{' '}
                                <small id="cur_process">list</small>
                              </span>
                            </div>
                          </div>
                          <div
                            className="portlet-body"
                            style={{
                              height: '418px',
                              overflowY: 'auto',
                              paddingRight: '15px',
                            }}
                          >
                            <table
                              className="table table-striped table-bordered table-advance table-hover"
                              id="db_leadevents"
                            >
                              <thead>
                                <tr>
                                  <th>Call Back Time</th>
                                  <th>Call Back Date</th>
                                  <th>Full Name</th>
                                  <th>Call Now</th>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard_agent
