import React, { Component } from 'react'
import $ from 'jquery'
import { NavLink } from 'react-router-dom'

const booleanopts = ['Yes', 'No']
class ClientAdd extends Component {
  state = { auto_add_tenant: true, server_id: 0, potential_tenant_url: '' }
  componentDidMount() {
    window.resizeHandle()

    //fetch and fill with info
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/SuperAdmin/AddClient', {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp)
        this.setState({
          server_id: resp.server,
          potential_tenant_url: resp.pot_ten_url,
        })
        window.potential_tenant_url = resp.pot_ten_url
        //load its specific js file ;)
        const script = document.createElement('script')
        script.src = 'jsdolphin/clientadd.js'
        script.async = true
        document.body.appendChild(script)
        ////////////////////////////////////
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
    ///////////////////////////////
  }
  componentWillUnmount() {
    window.removeJS('jsdolphin/clientadd.js')
  }
  handleAddUser = (e) => {
    e.preventDefault()
    window.mdl_adduser()
  }
  handleAddClient = (e) => {
    e.preventDefault()
    //so we regenrate UI here in case agent was not added to hidden input field of users
    window.all_users.regenUI()
    //////////////////
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/SuperAdmin/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_addclient').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <div className="page-head">
              <div className="container">
                <div className="page-title">
                  <h1>
                    Client
                    <small>&nbsp;&nbsp;&nbsp;&nbsp;List</small>
                  </h1>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <form
                              id="form_addclient"
                              onSubmit={this.handleAddClient}
                            >
                              <input
                                type="hidden"
                                name="server_id"
                                value={this.state.server_id}
                              />
                              <input
                                type="hidden"
                                name="action"
                                value="add_newClient"
                              />
                              <div className="col-md-6">
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="fullname"
                                          name="fullname"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Tenant</td>
                                      <td>
                                        <select
                                          disabled="disabled"
                                          className="form-control sel2_tenantlist"
                                          id="tenant_id"
                                          name="tenant_id"
                                        >
                                          <option></option>
                                        </select>
                                        <div className="checkbox">
                                          <label>
                                            <input
                                              type="checkbox"
                                              value="true"
                                              name="auto_add_tenant"
                                              id="auto_add_tenant"
                                              defaultChecked={
                                                this.state.auto_add_tenant
                                              }
                                              onChange={(e) => {
                                                let auto_add_tenant =
                                                  !this.state.auto_add_tenant
                                                window.toggle_tenant_sel(
                                                  auto_add_tenant
                                                )
                                                this.setState({
                                                  auto_add_tenant,
                                                })
                                              }}
                                            />{' '}
                                            <small>
                                              Add Tenant Automatically{' '}
                                            </small>
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Email</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="email"
                                          name="email"
                                        />
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Url Prefix</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="url_prefix"
                                          readonly
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Seats</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="num_seats"
                                          name="num_seats"
                                        />
                                      </td>
                                    </tr>
                                    {/*Enable recording adding here */}
                                    <tr>
                                      <td>Enable Recording</td>
                                      <td class="hidden-xs">
                                        <select
                                          className="form-control"
                                          id="cenable_rec"
                                          name="enable_recording"
                                        >
                                          <option value="Yes">Yes</option>
                                          <option value="No" selected>
                                            No
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Account Type</td>
                                      <td>
                                        <select
                                          id="account_type"
                                          name="account_type"
                                          className="bs-select form-control"
                                        >
                                          <option value="trial">Trial</option>
                                          <option value="regular">
                                            Regular
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Account Status</td>
                                      <td>
                                        <select
                                          id="account_status"
                                          name="account_status"
                                          className="bs-select form-control"
                                        >
                                          <option value="active">Active</option>
                                          <option
                                            value="inactive"
                                            selected={true}
                                          >
                                            Inactive
                                          </option>
                                        </select>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="hidden"
                                  name="action"
                                  value="add_newClient"
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td className="highlight">
                                        Users{' '}
                                        <a id="add_new_user">
                                          <i className="fa fa-plus"></i>
                                        </a>
                                      </td>
                                      <td
                                        className="hidden-xs accordion"
                                        id="acc_add_new_user"
                                      >
                                        <div className="panel panel-default">
                                          <div className="panel-heading">
                                            <h4 className="panel-title">
                                              <NavLink
                                                className="accordion-toggle accordion-toggle-styled"
                                                data-toggle="collapse"
                                                aria-expanded="true"
                                                data-parent="#acc_add_new_user"
                                                to="/"
                                              ></NavLink>
                                            </h4>
                                          </div>
                                          <div
                                            id="acc_add_new_user_cc"
                                            className="panel-collapse collapse in"
                                            aria-expanded="true"
                                          >
                                            <div className="panel-body">
                                              <input
                                                type="hidden"
                                                name="users"
                                                id="users_all"
                                                value=""
                                              />
                                              <div className="checkbox-list">
                                                <label id="user__3000">
                                                  3001 - - - Owner
                                                </label>
                                                <div
                                                  className="checkbox-list"
                                                  id="list_users"
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-md-12 text-center">
                                <button
                                  type="submit"
                                  className="btn green-jungle"
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade draggable-modal"
          id="modal_add_user"
          tabIndex="-1"
          arole="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">ADD NEW USER</h4>
              </div>
              <form
                className="form-horizontal"
                id="mdlfrm_user_add"
                onSubmit={this.handleAddUser}
              >
                <div className="modal-body">
                  <div className="form-body">
                    <div className="form-group">
                      <label className="control-label col-md-3">
                        User Name
                      </label>
                      <div className="col-md-4">
                        <input
                          type="name"
                          className="form-control"
                          id="mdl_user_name"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">Email</label>
                      <div className="col-md-4">
                        <input
                          type="email"
                          className="form-control"
                          id="mdl_user_email"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">Type</label>
                      <div className="col-md-4">
                        <select
                          className="sel2_in_modal form-control"
                          id="mdl_user_type"
                        >
                          <option value="agent">Agent</option>
                          <option value="manager">Manager</option>
                          <option value="owner">Owner</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ClientAdd
