import React, { Component } from 'react'
import $ from 'jquery'
import LeadStatusDropDown from '../Bricks/LeadStatusDropDown'
import UsersDropDown from '../Bricks/UsersDropDown'
import ListDropDown from '../Bricks/ListDropDown'
import { Link } from 'react-router-dom'
import { Checkbox, Select, Tag } from 'antd'
import HeadTitle from '../HeadTitle'
import LeadActivityWidget from './ViewSingleLeadBricks/LeadActivityWidget'
const { Option } = Select
export default class ViewSingleLead extends Component {
  state = {
    cc: '',
    lead_id: '',
    list_id: '',
    campaign_id: '',
    called_count: '',
    fname: '',
    lname: '',
    email: '',
    from_address: '',
    phone1: '',
    phone2: '',
    phone3: '',
    address1: '',
    address2: '',
    city: '',
    gmt_offset_now: '+00:00',
    state: '',
    zip_code: '',
    company: '',
    source: '',
    lead_status: '',
    owner: '',
    notes: '',
    custom_fields: [],
    script: '',
    last_call_dispo: '',
    email_templates: [],
    isContact: false,
    img: '',
    isDNC: '0',
    user_role: '',
    dispo_statuses: [],
    callbacks_notification: '',
    text_templates: [],
    lcd_schedule_callback: false,
    sel_scheduler_disabled: true,
    scheduler_event_type: 'callback',
    available_credits: 0,
    sms_ability: 'inactive',
    system_settings: null,
  }
  componentDidMount() {
    window.resizeHandle()
    const lead_id = this.props.match.params.lead_id
    this.initSummerNoteandEmailOnSLV(this.props.baseUrl, lead_id)
    window.load_select2('.bs-select')
    this.setState({
      user_role: this.props.user_role,
      system_settings: this.props.settings,
    })
    this.initLead()
  }
  componentWillReceiveProps = (nP) => {
    if (nP.user_role != this.state.user_role) {
      this.setState({ user_role: nP.user_role })
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.settings.allow_agent_to_callback_sch !==
      this.state.system_settings.allow_agent_to_callback_sch
    ) {
      this.setState({ system_settings: this.props.settings })
    }
  }
  initLead = () => {
    const lead_id = this.props.match.params.lead_id
    //fetch lead info so info
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Leads/View/' + lead_id, {
      credentials: 'include',
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          let lead = data.msg.lead.info
          let list_camp = data.msg.lead.list_and_campaign
          //console.log(lead);
          //console.log("HIIII");
          this.setState({
            lead_id: lead.lead_id,
            list_id: list_camp.list_id,
            campaign_id: list_camp.campaign_id,
            called_count: lead.called_count,
            fname: lead.first_name,
            lname: lead.last_name,
            email: lead.email,
            from_address: lead.from_address,
            phone1: lead.phone,
            phone2: lead.phone2,
            phone3: lead.phone3,
            address1: lead.address1,
            address2: lead.address2,
            city: lead.city,
            gmt_offset_now: lead.gmt_offset_now,
            state: lead.state,
            zip_code: lead.postal_code,
            company: lead.company,
            source: lead.source,
            lead_status: lead.lead_status,
            owner: lead.assigned_to,
            last_call_dispo: lead.last_call_dispo,
            notes: lead.comments,
            custom_fields: [], //lead.custom_fields,
            script: lead.script,
            email_templates: data.msg.templates,
            isContact: lead.isContact == 'Y' ? true : false,
            img: lead.img,
            isDNC: lead.isDNC,
          })
          //we load custom fields in span here ;)
          $('#disp_custom_sw_fields').html(lead.custom_fields)
          ///////////////////////////////////////////
          //console.error("asdsad");
          //console.error(data.msg.lead.info);
          window.$('.bs-select').trigger('change')
          //we have to load and show script :o\
          var Aurl =
            this.props.baseUrl +
            '/index.php/Ajax/get_Script/' +
            list_camp.script +
            '/' +
            lead.lead_id
          window.$.ajax({
            type: 'GET',
            url: Aurl,
            xhrFields: {
              withCredentials: true,
            },
            success: function (resp) {
              window
                .$('#summernote_text')
                .summernote('code', $('<div>').html(resp).text())
              window.$('#summernote_text').summernote('disable')
            },
          })
          //////////////////////////////////////////////
          //fetch for callbacks
          fetch(
            this.props.baseUrl +
              '/index.php/Ajax/getCallBacksDue/' +
              lead.lead_id,
            { credentials: 'include' }
          )
            .then((data) => data.json())
            .then((data) => {
              this.setState({
                callbacks_notification:
                  data.msg.callbacks_past > 0
                    ? this.returnTag('Past Due Callback', 'red')
                    : data.msg.callbacks_total > 0
                    ? this.returnTag('Upcoming Callback', 'orange')
                    : '',
                /*callbacks_total: data.msg.callbacks_total,
                callbacks_past: data.msg.callbacks_past,*/
              })
            })
            .catch((er) => {
              alert('exception')
            })
          ////
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
    //window.refresh_callNotes('#leadNotes', lead_id) ///refresh notes portion to show note
    // refresh_callNotes('#leadNotes', lead_id)
    ///////////////////////////////////
    //bring in count etc.,
    fetch(this.props.baseUrl + '/index.php/Ajax/getMyTextStats/true', {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp)
        if (resp.status) {
          this.setState({
            available_credits: resp.msg.available_credits,
            sms_ability: resp.msg.sms_ability,
          })
        }
      })
      .catch((resp) => {})
    //
  }
  printLead = () => {
    this.props.blockUI(true)
    //fetch custom fields and print stuff :)
    fetch(
      this.props.baseUrl +
        '/index.php/Manager/list_cf_sw_as_json/' +
        this.state.lead_id,
      { method: 'GET', credentials: 'include' }
    )
      .then((data) => data.json())
      .then((response) => {
        let cfields = response.msg

        var mywindow = window.open('', 'PRINT', 'height=400,width=600')

        mywindow.document.write(
          '<html><head><title> Dolphin Cloud Lead ID ' +
            this.state.lead_id +
            '</title><style>.head{text-align:center} th{text-align:left;padding-left:5px}</style>'
        )
        mywindow.document.write('</head><body >')
        mywindow.document.write(
          "<h4 class='head'> Dolphin Cloud Dialer</h1><h4 class='head'> Lead ID: " +
            this.state.lead_id +
            '</h4>'
        )

        mywindow.document.write('<table>')
        mywindow.document.write(
          '<tr><th>First Name</th><td>' +
            this.state.fname +
            '</td><th>Last Name</th><td>' +
            this.state.lname +
            '</td></tr>'
        )
        mywindow.document.write(
          '<tr><th>Email</th><td>' +
            this.state.email +
            '</td><th>Phone</th><td>' +
            this.state.phone1 +
            '</td></tr>'
        )
        mywindow.document.write(
          '<tr><th>Phone2</th><td>' +
            this.state.phone2 +
            '</td><th>Phone3</th><td>' +
            this.state.phone3 +
            '</td></tr>'
        )
        mywindow.document.write(
          '<tr><th>Address1</th><td>' +
            this.state.address1 +
            '</td><th>Address2</th><td>' +
            this.state.address2 +
            '</td></tr>'
        )
        mywindow.document.write(
          '<tr><th>City</th><td>' +
            this.state.city +
            '</td><th>State</th><td>' +
            this.state.state +
            '</td></tr>'
        )
        mywindow.document.write(
          '<tr><th>Zip Code</th><td>' +
            this.state.zip_code +
            '</td><th>Company</th><td>' +
            this.state.company +
            '</td></tr>'
        )
        mywindow.document.write(
          '<tr><th>Campaign ID</th><td>' +
            this.state.campaign_id +
            '</td><th>List ID</th><td>' +
            this.state.list_id +
            '</td></tr>'
        )
        mywindow.document.write(
          "<tr><th>Notes</th><td colspan='3'>" + this.state.notes + '</td></tr>'
        )

        //add custom fields
        let cnt = 1
        for (var cf in cfields) {
          if (cnt % 2 == 1) {
            mywindow.document.write('<tr>')
          }
          mywindow.document.write(
            '<th>' +
              cfields[cf].display_name +
              '</th><td>' +
              cfields[cf].value +
              '</td>'
          )
          cnt++
          if (cnt % 2 == 1 || cnt == cfields.length) {
            mywindow.document.write('</tr>')
          }
        }
        //
        mywindow.document.write(
          '<tr><th></th><td></td><th>Last Call Status</th><td>' +
            this.state.last_call_dispo +
            '</td></tr>'
        )
        mywindow.document.write('</table>')
        mywindow.document.write('</body></html>')

        mywindow.document.close() // necessary for IE >= 10
        mywindow.focus() // necessary for IE >= 10*/

        this.props.blockUI(false)
        mywindow.print()
        //mywindow.onbeforeprint = function(event) { alert('hi');mywindow.close(); };
        setTimeout(function () {
          mywindow.close()
        }, 3000)
      })
      .catch((resp) => {
        this.props.blockUI(false)
      })
    //

    /**
 * cc: "",
    lead_id: "",
    list_id: "",
    campaign_id: "",
    called_count: "",      
    zip_code: "",
    company: "",
    source: "",
    lead_status: "",
    owner: "",
    notes: "",
    custom_fields: [],
    script: "",
    last_call_dispo: "",
    email_templates: [],
    isContact: false,
    img: "",
    isDNC: "0",
    user_role: "",
    dispo_statuses: [],
    callbacks_notification: "",
    text_templates: [],
    lcd_schedule_callback: false,
    sel_scheduler_disabled: true,
    scheduler_event_type: "callback",
    available_credits: 0,
    sms_ability: "inactive",
 */
    return true
  }
  returnTag = (text, color) => {
    return (
      <Tag
        color={color}
        style={{
          margin: 'auto',
        }}
      >
        <Link
          style={{
            decoration: 'none',
            color: 'inherit',
          }}
          to={'/leadscallbacks/' + this.state.lead_id}
        >
          {text}
        </Link>
      </Tag>
    )
  }
  /*mapcustomfield=()=>{
		let cfields=this.state.custom_fields;
		var iter=0;
		var itemsPerRow=3;
		var div_open=false;
		var fields_html='';

		for(iter=0; iter<cfields.length; iter++){
			if(cfields[iter].type=='TEXT' || cfields[iter].type=='SELECT'){
				if(iter%itemsPerRow==0){
					div_open=true;
					fields_html='<div className="form-group"><div className="row">';
				}
			}
			fields_html
		}
	}*/
  handleLeadInfoSubmit = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_editLeadInfo').serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
        this.initLead()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleSubmitNote = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_postnote').serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
        this.initLead()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleSubmitEmail = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new FormData(e.target), //new URLSearchParams($("#form_postemail").serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
        this.initLead()
      })
      .catch((e) => {
        window.displayAlert(
          '#alert_container',
          'Failed to Send Email. Please check smtp settings',
          'danger'
        )
        this.props.blockUI(false)
      })
  }
  handleSubmitSMS = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_postSMS').serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
        this.initLead()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  MakeContact = (e) => {
    {
      /*href="<?=base_url('index.php/Leads/Makecontact/'.$lead['info']['lead_id'])?>"*/
    }
    this.props.blockUI(true)
    fetch(
      this.props.baseUrl + '/index.php/Leads/Makecontact/' + this.state.lead_id,
      { credentials: 'include', method: 'get' }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#static').modal('hide')
        this.props.blockUI(false)
        this.initLead()
      })
      .catch((e) => {
        window.$('#static').modal('hide')
        this.props.blockUI(false)
      })
  }
  MakeLead = (e) => {
    {
      /*<a href="<?=base_url('index.php/Leads/Makelead/'.$lead['info']['lead_id'])?>" className="btn green-jungle">Make A Lead</a>	*/
    }
    this.props.blockUI(true)
    fetch(
      this.props.baseUrl + '/index.php/Leads/Makelead/' + this.state.lead_id,
      { credentials: 'include', method: 'get' }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#static').modal('hide')
        this.props.blockUI(false)
        this.initLead()
      })
      .catch((e) => {
        window.$('#static').modal('hide')
        this.props.blockUI(false)
      })
  }
  handleAssignList = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_mdl_assignList').serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
        window.$('#AssignLeadToList').modal('hide')
        this.initLead()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  updateLastCallDispo = () => {
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Ajax/listAllStatuses', {
      credentials: 'include',
      method: 'get',
    })
      .then((data) => data.json())
      .then((data) => {
        var dispo_statuses = []
        for (var i = 0; i < data.length; i++) {
          var status_ob = {
            id: data[i].status,
            name: data[i].status_name,
          }
          dispo_statuses.push(status_ob)
        }
        this.setState({ dispo_statuses: dispo_statuses }, function () {
          window.$('#mdl_update_last_call_dispo').modal('show')
        })
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleLastCallDispoUpdate = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Leads/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_mdl_updateDispo').serialize()),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
        window.$('#mdl_update_last_call_dispo').modal('hide')
        this.initLead()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  spam_caller_id_check = (e, cid) => {
    window.App.blockUI()
    //alert($(this).attr("data-cid"));
    const Aurl = this.props.baseUrl
    fetch(Aurl + '/index.php/Ajax/scrap_for_spam_cid/' + cid, {
      credentials: 'include',
      method: 'get',
    })
      .then((data) => data.json())
      .then((data) => {
        window.$('#spam_check_cid').html(cid)
        window.$('#mdl_spmchk_cid').html(cid)
        window
          .$('#mdl_spmchk_score')
          .html(data.spam_score + '/' + data.spam_total)
        var spam_markers = ''
        for (var i = 0; i < data.spam_markers.length; i++) {
          if (i == 0)
            spam_markers =
              '<a target="_blank" href="' +
              data.spam_markers[i] +
              '">' +
              data.spam_markers[i] +
              '</a>'
          else
            spam_markers +=
              '<br /><a target="_blank" href="' +
              data.spam_markers[i] +
              '">' +
              data.spam_markers[i] +
              '</a>'
        }
        window.$('#mdl_spmchk_by').html(spam_markers)
        var spam_sources = ''
        for (var i = 0; i < data.spam_sources.length; i++) {
          if (i == 0)
            spam_sources =
              '<small><a target="_blank" href="' +
              data.spam_sources[i] +
              '">' +
              data.spam_sources[i] +
              '</a></small>'
          else
            spam_sources +=
              '<br /><small><a target="_blank" href="' +
              data.spam_sources[i] +
              '">' +
              data.spam_sources[i] +
              '</a></small>'
        }
        window.$('#mdl_spmchk_at').html(spam_sources)
        window.$('#mdl_spam_check_cid').modal('show')
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  /***********Text Template Events */
  fillTemplates = (search) => {
    if (search == undefined) search = ''
    fetch(this.props.baseUrl + '/index.php/Texting/list_select/' + search, {
      credentials: 'include',
      method: 'GET',
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ text_templates: data.msg })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  handleTemplateChange = (value) => {
    console.log(`selected ${value}`)
    fetch(
      this.props.baseUrl +
        '/index.php/Texting/view_parsed/' +
        value +
        '/' +
        this.state.lead_id,
      {
        credentials: 'include',
      }
    )
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          this.setState({ text_msg: data.msg })
        }
      })
      .catch((err) => {})
  }
  handleTemplateSearch = (search) => {
    this.fillTemplates(search)
  }
  handleTemplateClear = () => {
    this.fillTemplates()
  }
  handleTemplateFocus = () => {
    this.fillTemplates()
  }
  /************************************ */
  /***************************** */
  initSummerNoteandEmailOnSLV = (baseUrl, lead_id) => {
    window
      .$('#message')
      .summernote({ height: 200 })
      .on('summernote.change', (we, contents, $editable) => {
        window.$('#email_body').html(contents)
        window.$('#email_body').val(contents)
      })
    window.$('#templates_dropdown').change(() => {
      var template_id = window.$('#templates_dropdown').val()

      fetch(`${baseUrl}/index.php/Ajax/Ajax_template`, {
        method: 'POST',
        credentials: 'include',
        body: new URLSearchParams({ id: template_id, lead_id: lead_id }),
      })
        .then((ans) => ans.json())
        .then((ansj) => {
          window
            .$('#message')
            .summernote('code', window.$('<div/>').html(ansj.email_body).text())
          window.$('#subject').html(ansj.email_subject)
          window.$('#subject').val(ansj.email_subject)
          window.$('#email_body').html(ansj.email_body)
          window.$('#email_body').val(ansj.email_body)
        })
        .catch((er) => {})
    })
  }
  /******************************** */
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Lead" subtitle="">
              <div class="page-toolbar">
                <button
                  style={{ height: '62px' }}
                  id="slv_btn_refresh"
                  onClick={(e) => this.initLead()}
                  class="btn "
                >
                  <i class="icon-reload"></i>
                </button>
                <button
                  style={{ height: '62px' }}
                  id="slv_btn_print"
                  onClick={(e) => this.printLead()}
                  class="btn "
                >
                  <i class="fa fa-print"></i>
                </button>
              </div>
            </HeadTitle>
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div id="bootstrap_alerts_demo"></div>
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="profile-sidebar">
                        <div className="portlet light">
                          <div className="profile-userpic">
                            <img
                              src={this.state.img}
                              className="img-responsive"
                              alt=""
                            />{' '}
                          </div>

                          <div
                            className="profile-usertitle"
                            style={{ marginTop: '10px' }}
                          >
                            <div
                              className="profile-usertitle-name"
                              style={{ fontSize: '27px' }}
                            >
                              {this.state.fname + ' ' + this.state.lname}
                            </div>
                            <div
                              className="profile-usertitle-job"
                              style={{ fontSize: '16px' }}
                            >
                              {this.state.company}
                            </div>
                            <span>
                              <a data-target="#static" data-toggle="modal"></a>
                            </span>
                          </div>
                          <div style={{ width: '100%' }} class="text-center">
                            {this.state.callbacks_notification}
                          </div>
                          <hr
                            style={{ marginTop: '15px', marginBottom: '15px' }}
                          />

                          <div>
                            <h4 className="profile-desc-title">
                              Contact Information
                            </h4>
                            <div className="margin-top-20 profile-desc-link">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4 className="profile-sideabar-info-h">
                                    Type:
                                  </h4>
                                  <span>
                                    {this.state.isContact ? (
                                      <a
                                        data-target="#static"
                                        data-toggle="modal"
                                      >
                                        Contact
                                      </a>
                                    ) : (
                                      <a
                                        data-target="#static"
                                        data-toggle="modal"
                                      >
                                        Lead
                                      </a>
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <h4 className="profile-sideabar-info-h">
                                    Owner:
                                  </h4>
                                  <span>
                                    <a
                                      href={'#/leads/view/' + this.state.owner}
                                    >
                                      {this.state.owner}
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="margin-top-10 profile-desc-link">
                              <h4 className="profile-sideabar-info-h">
                                Email:
                              </h4>
                              <span>{this.state.email}</span>
                            </div>
                            <div className="margin-top-10 profile-desc-link">
                              <table>
                                <tr>
                                  <th valign="top">
                                    <h4 className="profile-sideabar-info-h">
                                      Phone:
                                    </h4>
                                  </th>
                                  <td>
                                    <span>
                                      {this.state.isDNC === '0' ? (
                                        <React.Fragment>
                                          <a
                                            className=""
                                            href={
                                              "javascript:sipCall('" +
                                              this.state.phone1 +
                                              "','" +
                                              this.state.lead_id +
                                              "','" +
                                              this.state.campaign_id +
                                              "');"
                                            }
                                          >
                                            {this.state.phone1}
                                          </a>
                                          &nbsp;
                                          {/*<a
                                            class="fa fa-exclamation-triangle"
                                            href="javascript:;"
                                            onClick={(e) =>
                                              this.spam_caller_id_check(
                                                e,
                                                this.state.phone1
                                              )
                                            }
                                          ></a>*/}
                                        </React.Fragment>
                                      ) : (
                                        <span
                                          style={{ color: 'red' }}
                                          title="This number has been previously dispositioned as DO NOT CALL."
                                        >
                                          {this.state.phone1}
                                        </span>
                                      )}
                                      {this.state.phone2 == '' ? (
                                        ''
                                      ) : (
                                        <React.Fragment>
                                          {this.state.isDNC === '0' ? (
                                            <React.Fragment>
                                              <br />
                                              <a
                                                className=""
                                                href={
                                                  "javascript:sipCall('" +
                                                  this.state.phone2 +
                                                  "','" +
                                                  this.state.lead_id +
                                                  "','" +
                                                  this.state.campaign_id +
                                                  "',2);"
                                                }
                                              >
                                                {this.state.phone2}
                                              </a>
                                              &nbsp;
                                              {/*<a
                                                class="fa fa-exclamation-triangle"
                                                href="javascript:;"
                                                onClick={(e) =>
                                                  this.spam_caller_id_check(
                                                    e,
                                                    this.state.phone2
                                                  )
                                                }
                                              ></a>*/}
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              <br />
                                              <span
                                                title="This number has been previously dispositioned as DO NOT CALL."
                                                style={{ color: 'red' }}
                                              >
                                                {this.state.phone2}
                                              </span>
                                            </React.Fragment>
                                          )}
                                        </React.Fragment>
                                      )}
                                      {this.state.phone3 == '' ? (
                                        ''
                                      ) : (
                                        <React.Fragment>
                                          {this.state.isDNC === '0' ? (
                                            <React.Fragment>
                                              <br />
                                              <a
                                                className=""
                                                href={
                                                  "javascript:sipCall('" +
                                                  this.state.phone3 +
                                                  "','" +
                                                  this.state.lead_id +
                                                  "','" +
                                                  this.state.campaign_id +
                                                  "',3);"
                                                }
                                              >
                                                {this.state.phone3}
                                              </a>
                                              &nbsp;
                                              {/*<a
                                                class="fa fa-exclamation-triangle"
                                                href="javascript:;"
                                                onClick={(e) =>
                                                  this.spam_caller_id_check(
                                                    e,
                                                    this.state.phone3
                                                  )
                                                }
                                              ></a>*/}
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              <br />
                                              <span
                                                title="This number has been previously dispositioned as DO NOT CALL."
                                                style={{ color: 'red' }}
                                              >
                                                {this.state.phone3}
                                              </span>
                                            </React.Fragment>
                                          )}
                                        </React.Fragment>
                                      )}
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div className="margin-top-10 profile-desc-link">
                              <h4 className="profile-sideabar-info-h">
                                Address:
                              </h4>
                              <span>{this.state.address1}</span>
                            </div>
                            <div className="margin-top-10 profile-desc-link">
                              <div className="row">
                                <div className="col-md-5">
                                  <h4 className="profile-sideabar-info-h">
                                    City:
                                  </h4>
                                  <span>{this.state.city}</span>
                                </div>
                                <div className="col-md-3">
                                  <h4 className="profile-sideabar-info-h">
                                    State:
                                  </h4>
                                  <span>{this.state.state}</span>
                                </div>
                                <div className="col-md-4">
                                  <h4 className="profile-sideabar-info-h">
                                    Zip:
                                  </h4>
                                  <span>{this.state.zip_code}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="profile-content">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="portlet light ">
                              <div className="portlet-title tabbable-line">
                                <div className="caption caption-md">
                                  <i className="icon-globe theme-font hide"></i>
                                  <span className="caption-subject font-blue-madison bold uppercase">
                                    Profile Account
                                  </span>
                                </div>
                                <ul className="nav nav-tabs">
                                  <li className="active">
                                    <a href="#tab_activities" data-toggle="tab">
                                      Activities
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#tab_info" data-toggle="tab">
                                      Lead Info
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#tab_script" data-toggle="tab">
                                      Script
                                    </a>
                                  </li>

                                  <li>
                                    <a href="#tab_email" data-toggle="tab">
                                      Email
                                    </a>
                                  </li>

                                  <li>
                                    <a href="#tab_text" data-toggle="tab">
                                      Text
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="portlet-body">
                                <div className="tab-content">
                                  <div className="tab-pane" id="tab_info">
                                    <form
                                      id="form_editLeadInfo"
                                      onSubmit={this.handleLeadInfoSubmit}
                                    >
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="update_lead_info"
                                      />
                                      <input
                                        type="hidden"
                                        name="lead_id"
                                        value={this.state.lead_id}
                                        onChange={(e) => {
                                          this.setState({
                                            lead_id: e.target.value,
                                          })
                                        }}
                                      />
                                      <input
                                        type="hidden"
                                        name="phone_code"
                                        className="form-control"
                                        value={this.state.phone_code}
                                      />

                                      <input
                                        type="hidden"
                                        name="title"
                                        className="form-control"
                                        value={this.state.title}
                                      />
                                      <input
                                        type="hidden"
                                        name="middle_initial"
                                        className="form-control"
                                        value={this.state.middle_initial}
                                      />
                                      <input
                                        type="hidden"
                                        name="address2"
                                        className="form-control"
                                        value={this.state.address2}
                                      />
                                      <input
                                        type="hidden"
                                        name="province"
                                        className="form-control"
                                        value={this.state.province}
                                      />
                                      <input
                                        type="hidden"
                                        name="country_code"
                                        className="form-control"
                                        value={this.state.country_code}
                                      />
                                      <input
                                        type="hidden"
                                        name="date_of_birth"
                                        className="form-control"
                                        value={this.state.date_of_birth}
                                      />
                                      <input
                                        type="hidden"
                                        name="email2"
                                        className="form-control"
                                        value={this.state.email2}
                                      />
                                      <input
                                        type="hidden"
                                        name="gender"
                                        className="form-control"
                                        value={this.state.gender}
                                      />
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              First Name
                                            </label>
                                            <input
                                              type="text"
                                              name="first_name"
                                              className="form-control"
                                              value={this.state.fname}
                                              onChange={(e) => {
                                                this.setState({
                                                  fname: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Last Name
                                            </label>
                                            <input
                                              type="text"
                                              name="last_name"
                                              className="form-control"
                                              value={this.state.lname}
                                              onChange={(e) => {
                                                this.setState({
                                                  lname: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Email
                                            </label>
                                            <input
                                              type="text"
                                              name="email"
                                              className="form-control"
                                              value={this.state.email}
                                              onChange={(e) => {
                                                this.setState({
                                                  email: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Phone{' '}
                                            </label>
                                            <input
                                              type="text"
                                              name="phone"
                                              className="form-control"
                                              value={this.state.phone1}
                                              minLength="10"
                                              maxLength="10"
                                              required
                                              onChange={(e) => {
                                                this.setState({
                                                  phone1: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Phone 2
                                            </label>
                                            <input
                                              type="text"
                                              name="phone2"
                                              className="form-control"
                                              minLength="10"
                                              maxLength="10"
                                              value={this.state.phone2}
                                              onChange={(e) => {
                                                this.setState({
                                                  phone2: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Phone 3
                                            </label>
                                            <input
                                              type="text"
                                              name="phone3"
                                              className="form-control"
                                              minLength="10"
                                              maxLength="10"
                                              value={this.state.phone3}
                                              onChange={(e) => {
                                                this.setState({
                                                  phone3: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label className="control-label">
                                              Address
                                            </label>
                                            <input
                                              type="text"
                                              name="address1"
                                              className="form-control"
                                              value={this.state.address1}
                                              onChange={(e) => {
                                                this.setState({
                                                  address1: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-2">
                                            <label className="control-label">
                                              City
                                            </label>
                                            <input
                                              type="text"
                                              name="city"
                                              className="form-control"
                                              value={this.state.city}
                                              onChange={(e) => {
                                                this.setState({
                                                  city: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-2">
                                            <label className="control-label">
                                              GMT Offset
                                            </label>
                                            <input
                                              type="text"
                                              name="gmt_offset_now"
                                              className="form-control"
                                              value={this.state.gmt_offset_now}
                                              onChange={(e) => {
                                                this.setState({
                                                  gmt_offset_now:
                                                    e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-2">
                                            <label className="control-label">
                                              State
                                            </label>
                                            <input
                                              type="text"
                                              name="state"
                                              className="form-control"
                                              value={this.state.state}
                                              onChange={(e) => {
                                                this.setState({
                                                  state: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-2">
                                            <label className="control-label">
                                              Zip Code
                                            </label>
                                            <input
                                              type="text"
                                              name="postal_code"
                                              className="form-control"
                                              value={this.state.zip_code}
                                              onChange={(e) => {
                                                this.setState({
                                                  zip_code: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-3">
                                            <label className="control-label">
                                              Company
                                            </label>
                                            <input
                                              type="text"
                                              name="company"
                                              className="form-control"
                                              value={this.state.company}
                                              onChange={(e) => {
                                                this.setState({
                                                  company: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-3">
                                            <label className="control-label">
                                              Source
                                            </label>
                                            <input
                                              type="text"
                                              name="source"
                                              className="form-control"
                                              value={this.state.source}
                                              onChange={(e) => {
                                                this.setState({
                                                  source: e.target.value,
                                                })
                                              }}
                                            />
                                          </div>
                                          <div className="col-md-3">
                                            <label className="control-label">
                                              Lead Status
                                            </label>
                                            {/*<select data-container="body" name="lead_status"  className="bs-select form-control">
																		</select>*/}
                                            <LeadStatusDropDown
                                              name="lead_status"
                                              class="bs-select form-control"
                                              baseUrl={this.props.baseUrl}
                                              id="lead_status"
                                              value={this.state.lead_status}
                                              onChange={(e) => {
                                                this.setState({
                                                  lead_status: e.target.value,
                                                })
                                              }}
                                            ></LeadStatusDropDown>
                                          </div>
                                          <div className="col-md-3">
                                            <label className="control-label">
                                              Owner{' '}
                                            </label>
                                            {/*<select name="assigned_to"  className="bs-select form-control">	
																			
																	</select>*/}
                                            <UsersDropDown
                                              name="assigned_to"
                                              class="bs-select form-control"
                                              baseUrl={this.props.baseUrl}
                                              id="assigned_to"
                                              value={this.state.owner}
                                              onChange={(e) => {
                                                this.setState({
                                                  owner: e.target.value,
                                                })
                                              }}
                                            ></UsersDropDown>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label className="control-label">
                                              Notes
                                            </label>
                                            <textarea
                                              name="comments"
                                              className="form-control"
                                              value={this.state.notes}
                                              onChange={(e) => {
                                                this.setState({
                                                  notes: e.target.value,
                                                })
                                              }}
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                      <span id="disp_custom_sw_fields"></span>
                                      <div className="row margin-top-10">
                                        <div className="col-md-4">
                                          <button
                                            href="javascript:;"
                                            className="btn green-jungle"
                                            type="submit"
                                          >
                                            {' '}
                                            Save Changes{' '}
                                          </button>
                                          {/*<a href="<?=$this->agent->referrer()?>" className="btn default"> Back </a>*/}
                                        </div>
                                        <div className="col-md-8 text-right">
                                          <span className="margin-right-10">
                                            Call Count:{' '}
                                            <b>{this.state.called_count}</b>
                                          </span>
                                          <span className="margin-right-10">
                                            <a
                                              data-target="#AssignLeadToList"
                                              data-toggle="modal"
                                            >
                                              List
                                            </a>
                                            : <b>{this.state.list_id}</b>
                                          </span>
                                          <span className="margin-right-10">
                                            Lead ID: <b>{this.state.lead_id}</b>
                                          </span>
                                          <span>
                                            Last Call Status:{' '}
                                            {this.state.user_role ==
                                              'manager' ||
                                            this.state.user_role == 'owner' ||
                                            this.state.user_role ==
                                              'superadmin' ||
                                            (this.state.system_settings !== null
                                              ? this.state.system_settings
                                                  .allow_agent_to_callback_sch ===
                                                '1'
                                              : false) ? (
                                              <a
                                                href="javascript:;"
                                                onClick={
                                                  this.updateLastCallDispo
                                                }
                                              >
                                                {this.state.last_call_dispo}
                                                &nbsp;{' '}
                                                <i className="fa fa-pencil"></i>
                                              </a>
                                            ) : (
                                              <b>
                                                {this.state.last_call_dispo}
                                              </b>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </form>
                                  </div>

                                  <div
                                    className="tab-pane active"
                                    id="tab_activities"
                                  >
                                    <LeadActivityWidget
                                      lead_id={this.state.lead_id}
                                      handleSubmitNote={this.handleSubmitNote}
                                      system_settings={this.props?.settings}
                                      user_role={this.props.user_role}
                                    />
                                    {/*<div className="portlet light portlet-fit ">
                                      <div className="portlet-body">
                                        <div className="timeline">
                                          <div className="timeline-item">
                                            <div className="timeline-badge">
                                              <div className="timeline-icon">
                                                <i className="icon-user font-green-jungle"></i>
                                              </div>
                                            </div>
                                            <div className="timeline-body">
                                              <div className="timeline-body-arrow">
                                                {' '}
                                              </div>
                                              <div className="timeline-body-head">
                                                <form
                                                  id="form_postnote"
                                                  onSubmit={
                                                    this.handleSubmitNote
                                                  }
                                                  method="post"
                                                >
                                                  <input
                                                    type="hidden"
                                                    name="action"
                                                    value="add_note"
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name="lead_id"
                                                    value={this.state.lead_id}
                                                  />
                                                  <textarea
                                                    name="note"
                                                    rows="4"
                                                    style={{ width: '100%' }}
                                                  ></textarea>
                                                  <button
                                                    type="submit"
                                                    className="btn btn-default pull-right"
                                                  >
                                                    Submit
                                                  </button>
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                          <span id="leadNotes"></span>
                                        </div>
                                        <div className="clearfix">
                                          <div
                                            id="record_count"
                                            className="pull-left"
                                          ></div>
                                          <div className="btn-toolbar pull-right">
                                            <div className="btn-group">
                                              <button
                                                type="button"
                                                className="btn btn-default"
                                                // id="btn_act_prev"
                                                onClick={(e)=>{
                                                  refresh_callNotes()
                                                }}
                                              >
                                                Prev
                                              </button>
                                            </div>
                                            <div className="btn-group">
                                              <button
                                                type="button"
                                                className="btn btn-default"
                                                id="btn_act_next"
                                              >
                                                Next
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>*/}
                                  </div>
                                  <div className="tab-pane" id="tab_script">
                                    <div className="row">
                                      <div
                                        name="script"
                                        id="summernote_text"
                                        className="col-md-12"
                                      >
                                        <div
                                          className="col-md-10 col-md-offset-1"
                                          style={{
                                            height: '600px',
                                            backgroundColor: '#f3f3f3',
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="tab-pane" id="tab_email">
                                    <form
                                      className="form-horizontal"
                                      id="form_postemail"
                                      onSubmit={this.handleSubmitEmail}
                                      encType="multipart/form-data"
                                    >
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="send_email"
                                      />
                                      <input
                                        type="hidden"
                                        name="lead_id"
                                        value={this.state.lead_id}
                                      />
                                      {/*<div className="inbox-compose-btn">
                                        <button className="btn green-jungle">
                                          <i className="fa fa-check"></i>Send
                                        </button>
                                        <div className="pull-right">
                                          <select
                                            id="templates_dropdown"
                                            className="form-control"
                                          >
                                            {this.state.email_templates.map(
                                              et => (
                                                <option
                                                  value={et.key}
                                                  key={et.key}
                                                >
                                                  {et.value}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>*/}
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="inbox-form-group mail-to">
                                            <label className="control-label">
                                              To:
                                            </label>
                                            <div className="controls controls-to">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="SendTo"
                                                id="to"
                                                value={this.state.email}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="inbox-form-group mail-to">
                                            <label className="control-label">
                                              From:
                                            </label>
                                            <div className="controls controls-to">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="SendFrom"
                                                id="from"
                                                value={this.state.from_address}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="inbox-form-group mail-to">
                                            <label className="control-label">
                                              CC:
                                            </label>
                                            <div className="controls controls-to">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="SendCC"
                                                id="snd_email_cc"
                                                value={this.state.cc}
                                                onChange={(e) => {
                                                  this.setState({
                                                    cc: e.target.value,
                                                  })
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="row"
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <div className="col-md-8">
                                          <div className="inbox-form-group">
                                            <label className="control-label">
                                              Subject:
                                            </label>
                                            <div className="controls">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="EmailSubject"
                                                id="subject"
                                              />{' '}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="inbox-form-group">
                                            <label className="control-label">
                                              Template:
                                            </label>
                                            <div className="controls">
                                              <select
                                                id="templates_dropdown"
                                                className="form-control"
                                                name="template_id"
                                              >
                                                {this.state.email_templates.map(
                                                  (et) => (
                                                    <option
                                                      value={et.key}
                                                      key={et.key}
                                                    >
                                                      {et.value}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="row"
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <div className="col-md-12">
                                          <div className="inbox-form-group">
                                            <label className="control-label pull-left">
                                              Attachments:
                                            </label>
                                            <div
                                              className="controls pull-left"
                                              style={{
                                                paddingTop: '5px',
                                                marginLeft: '10px',
                                              }}
                                            >
                                              <input
                                                type="file"
                                                class="form-control-file"
                                                id="attachments"
                                                name="attachments[]"
                                                multiple
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="inbox-form-group">
                                            <input
                                              type="hidden"
                                              name="EmailBody"
                                              id="email_body"
                                            />
                                            <textarea
                                              name="EmailMessage"
                                              id="message"
                                              tabindex="1"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-12">
                                          <button className="btn green-jungle">
                                            <i className="fa fa-check"></i>Send
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>

                                  <div className="tab-pane" id="tab_text">
                                    <form
                                      className="form-horizontal"
                                      id="form_postSMS"
                                      onSubmit={this.handleSubmitSMS}
                                    >
                                      <input
                                        type="hidden"
                                        name="action"
                                        value="send_sms"
                                      />
                                      <input
                                        type="hidden"
                                        name="lead_id"
                                        value={this.state.lead_id}
                                      />
                                      <input
                                        type="hidden"
                                        name="campaign_id"
                                        value={this.state.campaign_id}
                                      />
                                      <input
                                        type="hidden"
                                        name="list_id"
                                        value={this.state.list_id}
                                      />
                                      <div className="alert alert-info">
                                        <p>
                                          In order for the lead to receive the
                                          text message, they must have an SMS
                                          enabled phone. Please ensure you are
                                          following any local or national laws
                                          related to SMS messaging.{' '}
                                          <a
                                            href="https://support.evs7.com/knowledge-base/restrictions-on-sms-mms-content/"
                                            target="_blank"
                                          >
                                            View SMS restrictions.
                                          </a>
                                        </p>
                                        <b>
                                          <small>
                                            SMS Remaining:{' '}
                                            {this.state.available_credits}
                                          </small>
                                        </b>
                                      </div>
                                      <div className="form-group">
                                        <div className="col-md-12">
                                          <div className="input-icon">
                                            <i className="icon-screen-smartphone"></i>
                                            <textarea
                                              rows="3"
                                              id="text_msg"
                                              name="msg"
                                              className="form-control qlv_field"
                                              value={this.state.text_msg}
                                              onChange={(e) => {
                                                this.setState({
                                                  text_msg: e.target.value,
                                                })
                                              }}
                                              disabled={
                                                this.state.sms_ability ==
                                                  'inactive' ||
                                                this.state.available_credits ==
                                                  0
                                              }
                                            >
                                              {this.state.text_msg}
                                            </textarea>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="col-md-4  margin-top-10">
                                          <Select
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder="Select a Template"
                                            optionFilterProp="children"
                                            onChange={this.handleTemplateChange}
                                            onFocus={this.handleTemplateFocus}
                                            onSearch={this.handleTemplateSearch}
                                            filterOption={(input, option) =>
                                              option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                              0
                                            }
                                            allowClear={true}
                                            onClear={this.handleTemplateClear}
                                          >
                                            {this.state.text_templates.map(
                                              (template) => (
                                                <Option value={template.id}>
                                                  {template.name}
                                                </Option>
                                              )
                                            )}
                                          </Select>
                                        </div>
                                        <div className="col-md-4 pull-right margin-top-10">
                                          <button
                                            type="submit"
                                            className="btn green-jungle pull-right"
                                          >
                                            Send Message
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="static"
          className="modal fade"
          tabindex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {!this.state.isContact ? (
                <React.Fragment>
                  <div className="modal-body">
                    <p> Would you like to make it contact? </p>
                  </div>
                  <div className="modal-footer">
                    {/*href="<?=base_url('index.php/Leads/Makecontact/'.$lead['info']['lead_id'])?>"*/}
                    <button
                      onClick={this.MakeContact}
                      className="btn green-jungle"
                    >
                      Make A Contact
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-outline dark"
                    >
                      Cancel
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="modal-body">
                    <p> Would you like to make it Lead? </p>
                  </div>
                  <div className="modal-footer">
                    {/*<a href="<?=base_url('index.php/Leads/Makelead/'.$lead['info']['lead_id'])?>" className="btn green-jungle">Make A Lead</a>	*/}
                    <button
                      onClick={this.MakeLead}
                      className="btn green-jungle"
                    >
                      Make Lead
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-outline dark"
                    >
                      Cancel
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="AssignLeadToList"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">
                  Assign This Lead to Another List
                </h4>
              </div>
              <form
                className="form-horizontal"
                id="form_mdl_assignList"
                onSubmit={this.handleAssignList}
              >
                <input type="hidden" name="action" value="assignLeadToList" />
                <input
                  type="hidden"
                  name="lead_id"
                  value={this.state.lead_id}
                />
                <div className="modal-body">
                  <div className="form-group">
                    <div className="col-md-4 col-md-offset-1">
                      <label className="control-label">Assigned To</label>
                    </div>
                    <div className="col-md-6">
                      {/*<select
                        name="assignToList"
                        className="bs-select form-control"
                      ></select>*/}
                      <ListDropDown
                        id="mdl_lists"
                        name="assignToList"
                        inmodal={true}
                        class="bs-select"
                        value={this.state.list_id}
                        baseUrl={this.props.baseUrl}
                        onChange={(ev) => {
                          this.setState({
                            list_id: ev.target.value,
                          })
                        }}
                      ></ListDropDown>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="mdl_view_email_stuff"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Email View</h4>
              </div>
              <div className="modal-body">
                <div className="portlet-body">
                  <table className="table table-striped table-bordered table-advance table-hover">
                    <tbody>
                      <tr>
                        <td>Send To</td>
                        <td id="mdl_email_sent_to"></td>
                      </tr>
                      <tr>
                        <td>Send From</td>
                        <td id="mdl_email_sent_from"></td>
                      </tr>
                      <tr>
                        <td>Email Subject</td>
                        <td id="mdl_email_subject"></td>
                      </tr>
                      <tr>
                        <td>Email body</td>
                        <td id="mdl_email_body"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<!-- Modal for viewing email -->*/}
        <div
          className="modal fade"
          id="mdl_view_email_stuff"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Email View</h4>
              </div>
              <div className="modal-body">
                <div className="portlet-body">
                  <table className="table table-striped table-bordered table-advance table-hover">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>Send To</td>
                        <td id="mdl_email_sent_to"></td>
                      </tr>
                      <tr>
                        <td>Send From</td>
                        <td id="mdl_email_sent_from"></td>
                      </tr>
                      <tr>
                        <td>Email Subject</td>
                        <td id="mdl_email_subject"></td>
                      </tr>
                      <tr>
                        <td>Email body</td>
                        <td id="mdl_email_body"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/**<!-- /.modal -->*/}

        {/***********Last Call status change update etc */}
        {/*<!-- Modal for viewing email -->*/}
        <div
          className="modal fade"
          id="mdl_update_last_call_dispo"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <form
                className="form-horizontal"
                id="form_mdl_updateDispo"
                onSubmit={this.handleLastCallDispoUpdate}
              >
                <input type="hidden" name="action" value="updateDispo" />
                <input
                  type="hidden"
                  name="lead_id"
                  value={this.state.lead_id}
                />
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  ></button>
                  <h4 className="modal-title">Change Last Call Status</h4>
                </div>
                <div className="modal-body">
                  <div className="portlet-body">
                    <table className="table table-striped table-bordered table-advance table-hover">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>Last Call Dispo</td>
                          <td id="mdl_email_sent_to">
                            <select
                              className="form-control"
                              name="mdl_last_call_dispo"
                            >
                              <option></option>
                              {this.state.dispo_statuses.map((item, i) => (
                                <option
                                  key={item.id}
                                  value={item.id}
                                  selected={
                                    item.id + ' - ' + item.name ==
                                    this.state.last_call_dispo
                                      ? true
                                      : false
                                  }
                                >
                                  {item.id + ' - ' + item.name}
                                </option>
                              ))}
                              ;
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Checkbox
                              onChange={(e) => {
                                this.setState(
                                  {
                                    lcd_schedule_callback: e.target.checked,
                                    sel_scheduler_disabled: !e.target.checked,
                                  },
                                  function () {
                                    if (this.state.lcd_schedule_callback) {
                                      window
                                        .$('.date-time-picker')
                                        .datetimepicker({
                                          isRTL: window.App.isRTL(),
                                          format: 'mm-dd-yyyy HH:ii P',
                                          showMeridian: true,
                                          autoclose: true,
                                          pickerPosition: window.App.isRTL()
                                            ? 'bottom-right'
                                            : 'bottom-left',
                                          todayBtn: true,
                                          startDate: new Date(),
                                        })
                                    }
                                  }
                                )
                              }}
                            ></Checkbox>
                            <input
                              type="hidden"
                              name="event_type"
                              value={this.state.scheduler_event_type}
                            />
                            <Select
                              defaultValue="callback"
                              dropdownStyle={{ zIndex: 20000 }}
                              style={{
                                width: 'auto',
                                paddingLeft: '5px',
                              }}
                              disabled={this.state.sel_scheduler_disabled}
                              name="event_type"
                              onChange={(val) => {
                                this.setState({ scheduler_event_type: val })
                              }}
                            >
                              <Option value="callback">
                                Schedule Callback
                              </Option>
                              <Option value="appointment">
                                Schedule Appointment
                              </Option>
                            </Select>
                          </td>
                          <td>
                            {this.state.lcd_schedule_callback == true ? (
                              <input
                                type="text"
                                className="form-control date-time-picker"
                                name="lcd_cb_sch_at"
                                readOnly
                              />
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Yes
                  </button>
                </div>
              </form>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/**<!-- /.modal -->*/}
        {/****************************************************** */}
      </React.Fragment>
    )
  }
}
