import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { saveAs } from 'file-saver'
import $ from 'jquery'
import HeadTitle from '../../HeadTitle'
import { Pagination } from 'antd'

class ListsList extends Component {
  state = {
    lists: [],
    settings: null,
    user_role: 'agent',

    //for pagination and basic search for lists :)
    cpage: 0,
    total: 0,
    filtered: 0,
    squery: '',
    rpp: 15,
    //
  }
  getDataTimeout = null
  componentDidMount() {
    window.resizeHandle()

    this.retrieveLists()
    this.setState({
      settings: this.props.settings,
      user_role: this.props.user_role,
    })
  }
  componentDidUpdate() {
    if (
      this.state.settings == null &&
      this.props.settings !== null &&
      this.props.settings !== undefined
    ) {
      this.setState({ settings: this.props.settings })
    } else {
      if (this.props.settings !== null && this.props.settings !== undefined) {
        let st_change_needed = false
        for (var i in this.state.settings) {
          if (this.state.settings[i] !== this.props.settings[i]) {
            st_change_needed = true
            break
          }
        }
        if (st_change_needed) {
          this.setState({ settings: this.props.settings })
        }
      }
    }
    if (this.state.user_role !== this.props.user_role) {
      this.setState({ user_role: this.props.user_role })
    }
  }
  debRetrieveLists = () => {
    clearTimeout(this.getDataTimeout) //if handlechange is called within 1 second again then clear timeout and the previous call to fetch_geocodes in wait will not execute
    this.getDataTimeout = setTimeout(this.retrieveLists, 1000) // call fetch_geocodes after 1 second of wait
  }
  retrieveLists = async () => {
    try {
      window.App.blockUI()
      const formData = new URLSearchParams()
      formData.append('cpage', this.state.cpage)
      formData.append('squery', this.state.squery)
      formData.append('rpp', this.state.rpp)

      const ans = await fetch(
        this.props.baseUrl + '/index.php/Lists/lists_list',
        {
          method: 'POST',
          body: formData,
          credentials: 'include',
        }
      )

      const resp = await ans?.json()
      this.setState({
        lists: resp.msg.all_lists,
        total: resp?.msg?.total_results,
        filtered: resp?.msg?.count_of_filtered_lists,
      })
      window.initConfirmation()
      window.App.initUniform()
    } catch (e) {
    } finally {
      window.App.unblockUI()
    }
  }
  toggleListActive = (event, list_id) => {
    let body = {
      action: 'toggle_list_status',
      list_id: list_id,
      status: event.target.checked ? 'Y' : 'N',
    }
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/Lists/Ajax', {
      method: 'POST',
      credentials: 'include',
      body: new URLSearchParams($.param(body)),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.warn(resp)
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleDeleteList = (ev, i) => {
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/Lists/post_process', {
      method: 'POST',
      credentials: 'include',
      body: new URLSearchParams(
        $.param({
          action: 'delete_list',
          list_id: this.state.lists[i].list_id,
        })
      ),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')

          var lists = [...this.state.lists]
          lists.splice(i, 1)
          this.setState({ lists })
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  handleListDownload = (e, list_id) => {
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/Lists/post_process', {
      method: 'POST',
      credentials: 'include',
      body: new URLSearchParams('list_id=' + list_id + '&action=download_list'),
    })
      .then(function (response) {
        return response.blob()
      })
      .then(function (blob) {
        let ddd = new Date()
        const filename =
          'dolList_' +
          list_id +
          'D' +
          ddd.getMonth() +
          '-' +
          ddd.getDate() +
          '-' +
          ddd.getFullYear() +
          'T' +
          ddd.getHours() +
          '_' +
          ddd.getMinutes() +
          '_' +
          ddd.getSeconds() +
          '.csv'
        saveAs(blob, filename)
        window.App.unblockUI()
      })
      .catch((error) => {
        window.App.unblockUI()
        console.log(error)
      })
  }
  display_download_button = (user_role, settings, list) => {
    let show_btn = true
    let button = (
      <button
        className="btn dark btn-sm btn-outline sbold uppercase"
        onClick={(e) => this.handleListDownload(e, list.list_id)}
      >
        <i className="fa fa-download"></i>{' '}
      </button>
    )
    if (this.state.user_role == 'agent') return ''
    if (this.state.user_role == 'manager') {
      show_btn = false
      if (settings !== null) {
        if (settings.mgr_can_exports_leads_reports == 'yes') {
          show_btn = true
        }
      }
    }
    if (show_btn) {
      return button
    }
    return ''
  }
  format_campaign = (id, name) => {
    return (
      <React.Fragment>
        {name || ''}&nbsp;<small>{id || ''}</small>
      </React.Fragment>
    )
  }
  total_pages = () => {
    const tp = Math.ceil(this.state.filtered / this.state.rpp)
    const extr = this.state.filtered % this.state.rpp
    return tp + extr
  }
  itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Lists" subtitle="view" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="portlet light portlet-fit ">
                        {/************Adding this header for lists */}
                        <div class="portlet-title">
                          <div class="caption">
                            <i class="fa fa-cogs font-green-sharp"></i>
                            <span class="caption-subject font-green-sharp bold uppercase">
                              Lists
                            </span>
                          </div>
                          <div class="tools">
                            <input
                              onChange={(e) => {
                                this.setState(
                                  { squery: e.target?.value },
                                  () => {
                                    this.setState({ cpage: 0 }, () => {
                                      this.debRetrieveLists()
                                    })
                                  }
                                )
                              }}
                              value={this.state.squery}
                              type="search"
                              class="form-control input-small input-inline"
                              placeholder=""
                              aria-controls="sample_1"
                            />
                          </div>
                        </div>
                        {/************************************************** */}

                        <div className="portlet-body">
                          <table className="table table-striped table-bordered table-advance table-hover">
                            <thead>
                              <tr>
                                <th>List ID</th>
                                <th>List Name</th>
                                <th>Leads (Dialables)</th>
                                <th>Campaign</th>
                                <th>Active</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.lists.map((list, i) => (
                                <tr>
                                  <td>{list.list_id}</td>
                                  <td>{list.list_name}</td>
                                  <td>
                                    {list.leads + ' (' + list.dialables + ')'}{' '}
                                  </td>
                                  <td>
                                    {this.format_campaign(
                                      list.campaign_id,
                                      list.campaign_name
                                    )}
                                  </td>
                                  <td>
                                    <label>
                                      <input
                                        type="checkbox"
                                        onClick={(ev) => {
                                          this.toggleListActive(
                                            ev,
                                            list.list_id
                                          )
                                          let tstate = this.state
                                          if (ev.target.checked)
                                            tstate.lists[i].active = 'Y'
                                          else tstate.lists[i].active = 'N'
                                          this.setState(tstate)
                                        }}
                                        checked={list.active == 'Y'}
                                      />{' '}
                                    </label>
                                  </td>
                                  <td>
                                    <Link
                                      to={'/crm/leadsbylist/' + list.list_id}
                                      className="btn dark btn-sm btn-outline sbold uppercase"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                    {this.display_download_button(
                                      this.state.user_role,
                                      this.state.settings,
                                      list
                                    )}

                                    <a
                                      href={'#/admin/listedit/' + list.list_id}
                                      className="btn dark btn-sm btn-outline sbold uppercase"
                                    >
                                      <i className="fa fa-pencil"></i> Modify
                                    </a>
                                    <button
                                      type="submit"
                                      data-toggle="confirmation"
                                      data-placement="top"
                                      data-singleton="true"
                                      data-popout="true"
                                      className="btn red btn-sm btn-outline sbold uppercase"
                                      onClick={(ev) => {
                                        this.handleDeleteList(ev, i)
                                      }}
                                    >
                                      <i className="fa fa-trash"></i> Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ paddingBottom: '10px' }}
                            >
                              <Pagination
                                showSizeChanger
                                current={this.state.cpage + 1}
                                pageSize={this.state.rpp}
                                size="small"
                                showTotal={(total, range) =>
                                  `${range[0]}-${range[1]} of ${total} lists`
                                }
                                total={this.state.filtered}
                                itemRender={this.itemRender}
                                onShowSizeChange={(page, pageSize) => {
                                  this.setState(
                                    {
                                      cpage: page - 1,
                                      rpp: pageSize,
                                    },
                                    () => {
                                      this.retrieveLists()
                                    }
                                  )
                                }}
                                onChange={(page, pageSize) => {
                                  this.setState(
                                    {
                                      cpage: page - 1,
                                      rpp: pageSize,
                                    },
                                    () => {
                                      this.retrieveLists()
                                    }
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ListsList
