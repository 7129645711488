import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal, notification, Divider, Space } from "antd";
import { HashRouter, Route, Switch } from "react-router-dom";
import { createHashHistory } from "history";
import $ from "jquery";
import BlockUi from "react-block-ui";
import ModalVideo from "react-modal-video"; //getting started video
import "react-block-ui/style.css";
//import logo from "./logo.svg";
import "./App.css";
import "react-modal-video/css/modal-video.min.css"; //getting started video

import Header from "./Components/Header";
import WebphoneBar from "./Components/WebphoneBar";
import Footer from "./Components/Footer";
import FloatingPdList from "./Components/Bricks/FloatingPdList";

import Login from "./Components/Loginn";
import DashboardSwitcher from "./Components/DashboardSwitcher";
import LeadsList from "./Components/CRM/LeadList";
import ContactList from "./Components/CRM/ContactList";
import CompanyList from "./Components/CRM/CompanyList";
import CompanyEdit from "./Components/CRM/CompanyEdit";
import MyContactList from "./Components/CRM/MyContactList";
import MyLeadList from "./Components/CRM/MyLeadList";
import Calendar from "./Components/CRM/Calendar";

import ViewSingleLead from "./Components/ExtraPages/ViewSingleLead";
import ViewSingleContact from "./Components/ExtraPages/ViewSingleContact";

import CampaignAdd from "./Components/AddNew/CampaignAdd";
import CompanyAdd from "./Components/AddNew/CompanyAdd";
import ContactAdd from "./Components/AddNew/ContactAdd";
import LeadAdd from "./Components/AddNew/LeadAdd";
import LeadImport from "./Components/AddNew/LeadImport";
import ListAdd from "./Components/AddNew/ListAdd";

import ServerAdd from "./Components/pbxServers/ServerAdd";
import ServerEdit from "./Components/pbxServers/ServerEdit";
import ServerList from "./Components/pbxServers/ServerList";
import TenantAdd from "./Components/pbxServers/TenantAdd";
import TenantEdit from "./Components/pbxServers/TenantEdit";

import DolphinClientList from "./Components/DolphinClients/NewClientList";
import DolphinClientAdd from "./Components/DolphinClients/ClientAdd";
import DolphinClientEdit from "./Components/DolphinClients/ClientEdit";

import ReportAdminLog from "./Components/Reports/ReportAdminLog";
import ReportAgentPerformanceDetail from "./Components/Reports/ReportAgentPerformanceDetail";
import ReportCDR from "./Components/Reports/ReportCDR";
import ReportLeadsExport from "./Components/Reports/ReportLeadsExport";
import ReportExtensionSummary from "./Components/Reports/ReportExtensionSummary";
import ReportOutboundCalling from "./Components/Reports/ReportOutboundCalling";
import ReportRealTime from "./Components/Reports/ReportRealTime";

import Configurations from "./Components/Admin/Configurations/Configurations";
import CustomFields from "./Components/Admin/CustomFields";
import CustomFields_edit from "./Components/Admin/CustomFields_edit";
import SystemFields_edit from "./Components/Admin/SystemFields_edit";
import MasterDialSettings from "./Components/Admin/MasterDialSettings";
import SystemSettings from "./Components/Admin/SystemSettings";

import RecordingAdd from "./Components/Admin/AudioFiles/RecordingAdd";
import RecordingList from "./Components/Admin/AudioFiles/RecordingList";

import CampaignList from "./Components/Admin/Campaigns/CampaignList";

import EmailTemplateAdd from "./Components/Admin/EmailTemplates/TemplateAdd";
import EmailTemplateEdit from "./Components/Admin/EmailTemplates/TemplateEdit";
import EmailTemplateList from "./Components/Admin/EmailTemplates/TemplateList";
import EmailTemplateSettings from "./Components/Admin/EmailTemplates/TemplateSettings";
import UsersEmailConfig from "./Components/Admin/EmailTemplates/UsersEmailConfig";

import TextTemplateAdd from "./Components/Admin/Texting/TextTemplateAdd";
import TextTemplateEdit from "./Components/Admin/Texting/TextTemplateEdit";
import TextTemplates from "./Components/Admin/Texting/TextTemplates";
import TextDetails from "./Components/Admin/Texting/TextDetails";

import ListDNC from "./Components/Admin/Lists/ListDNC";
import ListsList from "./Components/Admin/Lists/ListsList";
import ListEdit from "./Components/Admin/Lists/ListEdit";

import DIDadd from "./Components/Admin/Inbound/DIDadd";
import DIDlist from "./Components/Admin/Inbound/DIDlist";
import RingGroupAdd from "./Components/Admin/Inbound/RingGroupAdd";

import ScriptAdd from "./Components/Admin/Scripts/ScriptAdd";
import ScriptList from "./Components/Admin/Scripts/ScriptList";
import ScriptEdit from "./Components/Admin/Scripts/ScriptEdit";

import WorkflowList from "./Components/Admin/DispoWorkflows/WorkflowList";
import WorkflowAdd from "./Components/Admin/DispoWorkflows/WorkflowAdd";
import WorkflowEdit from "./Components/Admin/DispoWorkflows/WorkflowEdit";

import UserAdd from "./Components/Admin/Users/UserAdd";
import UserEdit from "./Components/Admin/Users/UserEdit";
import UserList from "./Components/Admin/Users/UserList";

import UserGroupAdd from "./Components/Admin/UserGroups/UserGroupAdd";
import UserGroupEdit from "./Components/Admin/UserGroups/UserGroupEdit";
import UserGroupList from "./Components/Admin/UserGroups/UserGroups.jsx";

import CampaignEdit from "./Components/Admin/Campaigns/CampaignEdit";
import Dashboard_agent from "./Components/Dashboard_agent";

import LeadEventAdd from "./Components/LeadEvents/LeadEventAdd";
import LeadEventEdit from "./Components/LeadEvents/LeadEventEdit";

import MyPerfReport from "./Components/Agent/MyPerfReport";

/***********Imprting test stuff 
import LeadList from "./Components/TestCom/LeadList";
/************************* */

import MyProfile from "./Components/MyProfile";
import MyPam from "./Components/MyPAM";
import MyCallBacks from "./Components/MyCallbacks";
import LeadsCallBacks from "./Components/Standalone/LeadsCallbacks";
import ReportCDR_n from "./Components/Reports/ReportCDR_n";
import WhitelistedCids from "./Components/DolphinClients/WhitelistedCids.jsx";

export const history = createHashHistory();

class App extends Component {
  state = {
    loading: false,
    isLoggedIn: false,
    baseUrl: "",
    login_id: "",
    user_role: "agent",
    user_id: "",
    client_id: "",
    user: "",
    password: "",
    do_register: false,
    allowed_seats: 0,
    filled_seats: 0,
    server_id: "",
    no_caller_id: true,
    email_settings_ok: false,
    account_active: "",
    user_account_status: "",
    settings: {},
    timezone: "UTC",
    onlyClientManager: false,
    user_group: false,
    imageversion: 0.01,
    showGettingStartedModal: false,
    gettingStartedModalxdOut: false,
    app_version: 3,
    float_power_dial_list: [],
  };
  called_once = false;
  noCallerIdNot = "";
  cursorX = 0;
  cursorY = 0;
  cmposX = 0;
  cmposY = 0;
  loginCheckInterval = null;
  constructor() {
    super();
    /*window.AJAX_PROCESS_URLRJS = this.state.baseUrl =
      "https://localhost/custom_pages/dolphindev.evs7.com"; //window.location.href; local development*/
    window.AJAX_PROCESS_URLRJS = this.state.baseUrl =
      // "https://localhost/Dolphin_BE"
      window.location.origin + "/backend_spa"; //production*/
    //console.log(this.state.baseUrl);
    //history.push("/"); //we are taking it back to dashboard everyti,e user reloads page
    this.init_vars();
  }
  init_vars = function () {
    var url = (window.url = this.state.baseUrl + "/index.php/Ajax/");
    window.call_state = 0; //0 mean in off hook. 1 means on hook. 2 means in dispo
    window.lead_preview = "on"; //it can be off or on. IN case its on we'll have to show lead and not dial
    //window.url="https://fs.asitss.com/Dolphin/index.php/Ajax/";
    window.disp_url = url + "list_dispo_statuses";
    window.dial_next_lead_url = url + "next_dialable";
    window.call_start_url = url + "call_started";
    window.gen_call_uuid_url = url + "obtain_unique_id";
    window.call_status_update_url = url + "update_call_status";
    window.call_end_url = url + "call_ended";
    window.lead_detail_url = url + "get_lead_info";
    window.get_lead_id_by_pnum = url + "add_pnum_as_lead";
    window.check_phone_status = url + "check_phone_status";
    window.remove_lead_by_id = url + "remove_lead";
    window.add_note_url = url + "addNote";
    window.update_live_call_status_url = url + "record_live_call";
    window.update_leads_dispo_url = url + "submit_dispo";
    window.lead_activity_detail_url = url + "get_leads_activity";
    window.callback_add_url = url + "add_new_callback";
    window.get_camp_info = url + "get_campaign_detail";
    window.campaign_id = "100";
    window.dispo_as = "";
    window.power_dialing = false;
    window.click2call = true; //this variable is awlays tru except when powerdialign starts, then its set to false. When call is terminated it checks if powerdialing is false we set this back to true
    window.lead_dialed = "";
    window.dialed_lead_is_new = false;
    window.phone_dialed = "";
    window.call_uuid = "";
    window.pbx_sip_call_id = "";
    window.incall_sec = 0;
    window.call_ringer = null;
    window.is_call_back = false; //make it true when its a call back from user
    window.is_btm_call_bar_visible = false;
    window.list_settings = {};
    window.campaign_settings = {};

    ///////////////////////////////////////the variable to call phone woho///////////////////////////////////////
    window.called_phone = 0; //0 means conventional phone1 is called, 1 means its second attempt and trying to engage phone2, 2 means going for phone3
    //////////////////////////////////////////////////////////////////////////////////////
    window.hangup_reason = "system"; //that's how we will record hangup reason :)
    /////////////////////////////////////////////////////////////////////////////////////////
    //////////////////hotkeys active in////////
    window.hotkeys_active = true;
    window.hotkey_pressed = false;
    /////////////////////////////////////////////////////
    window.sys_settings = { auto_answer_incoming: "N" };
    //campaign/list stats
    window.stat_dialable_count = 0;
    //////////////////////////
    window.notifi8_settings_err = {
      theme: "ruby",
      sticky: false,
      horizontalEdge: "bottom",
      verticalEdge: "right",
      zIndex: 99999,
    };
    window.notifi8_settings_succ = {
      theme: "lime",
      sticky: true,
      horizontalEdge: "bottom",
      verticalEdge: "right",
      life: "0",
      zIndex: 99999,
    };
    //alert message if showed on other page
    window.global_alert_msg = "";
  };
  init_dom = () => {
    try {
      /**************call items*/
      window.btn_xfer_blind = window.$("#xfer_blind");
      window.li_call = window.$("#wp_newCall");
      //var li_hangup=$('#wp_Hangup');
      window.li_mute = window.$("#wp_Mute");
      window.li_hold = window.$("#wp_Hold");
      window.li_trx = window.$("#wp_Trx");
      window.li_record = window.$("#wp_Record");
      window.li_DTMF = window.$("#wp_DTMF");
      window.btn_DTMF = window.$("#btn_sendDTMF");
      window.txt_DTMF = window.$("#txtDTMF");
      window.li_powDialing = window.$("#wp_powDialer");
      window.li_stopPowDialing = window.$("#wp_s_powDialer");
      window.sel_campaign_id = window.$("#campaign_id");
      window.xfer_number = window.$("#xfer_num");

      window.phone_status_msg = window.$("#phone_status_msg");
      window.phone_connecting_anim = window.$("#phone_connecting_anim");
      window.phone_incall_anim = window.$("#phone_incall_anim");

      window.call_bar_toggler = window.$("#wp_callbar_toggler");
      window.sel_dispoList = window.$("#wp_DispoSelect");

      window.btn_skipLead = window.$("#wp_skipLead");
      /*****************************************/
    } catch (e) { }
  };
  componentDidUpdate() {
    this.init_dom();
  }
  componentDidMount() {
    //so we check if its not a chrome
    var isChrome =
      navigator.userAgent.includes("Chrome") &&
      navigator.vendor.includes("Google Inc");
    if (!isChrome) {
      window.bootbox.alert(
        "It seems like you're not using Chrome Browser, which is recommended browser for using Dolphin. On Browsers other than chrome the App can behave unexpectedly."
      );
    }
    //
    /******************init vars */
    window.webphone = null;
    /******************************************** */

    this.initMain(); //initialize main we will make this into setInterval
    this.loginCheckInterval = setInterval(this.checkifStillLoggedIn, 30000);

    this.init_dom();
  }
  componentWillUnmount() {
    if (window.webphone != undefined) window.webphone.wp_unregister();
    clearInterval(this.loginCheckInterval);
  }
  initMain = () => {
    this.setState({ loading: true });
    fetch(this.state.baseUrl + "/index.php/Login/index", {
      credentials: "include",
    })
      .then((blob) => blob.json())
      .then((data) => {
        //here we will get agreement status and display a modal in case its unsigned//
        this.getAgreementStatus();
        ////////////////////////////////////////////////////////////////////////////////
        console.log(data);
        this.setState({ isLoggedIn: data.status });
        this.setState({ loading: false });

        if (data.status) {
          this.setState(
            {
              user_role: data.creds.role,
              user_id: data.creds.user_id,
              client_id: data.creds.client_id,
              user: data.creds.user,
              password: data.creds.password,
              do_register: data.creds.do_register,
              allowed_seats: data.creds.allowed_seats,
              filled_seats: data.creds.filled_seats,
              server_id: data.creds.server_id,
              no_caller_id: data.creds.noCallerId,
              email_settings_ok: data.creds.are_email_settings_ok,
              account_active: data.creds.client_account_active,
              user_account_status: data.creds.status,
              settings: data.creds.settings_allowed,
              user_group: data.creds.user_group,
            },
            function () {
              //set auto answer var
              try {
                window.sys_settings.auto_answer_incoming =
                  this.state.settings.auto_answer_incoming;
                window.sys_settings.early_media =
                  this.state.settings.early_media;
                window.sys_settings.international_dialing =
                  this.state.settings.international_dialing;
                window.sys_settings.can_play_recordings = this.state.settings.can_play_recordings;
                window.user_role = this.state.user_role;
              } catch (e) { }
              //
              this.setPAMlabel();
              //if client id is -1 then we dont show any menus other than client manager and pbx servers
              console.log(data.creds.client_id);
              console.log(window.location.href);
              if (
                data.creds.client_id == -1 &&
                !window.location.href.includes("localhost")
              ) {
                this.setState({ onlyClientManager: true });
                history.push("/dolphinclients/view");
              }
              //////////////////////////////////////////////////////////////////////////////////////////////
              window.tenant_url = data.creds.tenant.tenant_url;
              window.wss_tenant_url = data.creds.do_register
                ? data.creds.tenant.wss_tenant_url
                : "";
              window.extension = data.creds.user_id;
              window.wss_username = data.creds.user_id;
              window.wss_pass = data.creds.password;
              window.wss_cid = data.creds.num_CallerId;
              window.is_account_active = data.creds.client_account_active;

              try {
                window.user_account_active =
                  data.creds.status.toLowerCase() == "active" ? true : false;
                //if (this.state.user_account_status.toLowerCase() == "active")
              } catch (e) { }

              // alert("hi");
            }
          );

          window.dialer_init();
        }
      });
  };

  setPAMlabel = () => {
    //do a fetch for pams, to disable enable stuff ;)
    fetch(this.state.baseUrl + "/index.php/Recording/pam_state", {
      method: "GET",
      credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status) {
          var pam1_html =
            "<option value='Pam - 1'>" +
            (data.pams.lbl_pam1 == ""
              ? this.state.settings.lbl_pam1
              : data.pams.lbl_pam1) +
            "</option>";
          var pam2_html =
            "<option  value='Pam - 2'>" +
            (data.pams.lbl_pam2 == ""
              ? this.state.settings.lbl_pam2
              : data.pams.lbl_pam2) +
            "</option>";
          var pam3_html =
            "<option  value='Pam - 3'>" +
            (data.pams.lbl_pam3 == ""
              ? this.state.settings.lbl_pam3
              : data.pams.lbl_pam3) +
            "</option>";
          var pam4_html =
            "<option  value='Pam - 4'>" +
            (data.pams.lbl_pam4 == ""
              ? this.state.settings.lbl_pam4
              : data.pams.lbl_pam4) +
            "</option>";
          var pam5_html =
            "<option  value='Pam - 5'>" +
            (data.pams.lbl_pam5 == ""
              ? this.state.settings.lbl_pam5
              : data.pams.lbl_pam5) +
            "</option>";
          if (data.pams.pam1 == false) {
            pam1_html =
              "<option disabled style='color: gray; '>" +
              this.state.settings.lbl_pam1 +
              "</option>";
          }
          if (data.pams.pam2 == false) {
            pam2_html =
              "<option disabled style='color: gray; '>" +
              this.state.settings.lbl_pam2 +
              "</option>";
          }
          if (data.pams.pam3 == false) {
            pam3_html =
              "<option disabled style='color: gray; '>" +
              this.state.settings.lbl_pam3 +
              "</option>";
          }
          if (data.pams.pam4 == false) {
            pam4_html =
              "<option disabled style='color: gray; '>" +
              this.state.settings.lbl_pam4 +
              "</option>";
          }
          if (data.pams.pam5 == false) {
            pam5_html =
              "<option disabled style='color: gray; '>" +
              this.state.settings.lbl_pam5 +
              "</option>";
          }
          window
            .$("#sendPAMdd")
            .html(
              '<option value="">Drop Voicemail</option>' +
              pam1_html +
              pam2_html +
              pam3_html +
              pam4_html +
              pam5_html
            );
        }
      });
    ///////////////////////////////////////////////////////
  };
  checkifStillLoggedIn = () => {
    if (this.state.isLoggedIn == false) return;
    var Aurl = this.state.baseUrl;
    if (
      this.cmposX != this.cursorX ||
      this.cmposY != this.cursorY ||
      !this.called_once
    ) {
      fetch(
        this.state.baseUrl +
        "/index.php/Login/IfStillLoggedIn/" +
        this.state.login_id,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((data) => {
          return data.json();
        })
        .then((jdata) => {
          //here we will get agreement status and display a modal in case its unsigned//
          this.getAgreementStatus();
          ////////////////////////////////////////////////////////////////////////////////
          let data = jdata;
          //console.error(data);
          //return;
          if (data.login == false) {
            this.setState({ isLoggedIn: false });

            if (data.reason === "1") {
              var msg = "You've been logged out due to inactivity.";
              if (data.login_log.forced_logout == "1") {
                msg =
                  "You have been logged out by user " +
                  data.login_log.logout_by;
              }
              if (this.state.isLoggedIn == true)
                window.bootbox.alert(msg, function (result) {
                  window.location.href = window.location.origin;
                });
            } else {
              window.bootbox.alert(
                "You've been logged out due to inactivity.",
                function (result) {
                  window.location.href = window.location.origin;
                }
              );
            }
          } else {
            console.log("We got this data", data);
            if (data.require_re_login == "1") {
              Modal.destroyAll();
              Modal.error({
                title: "Re-Login required",
                content:
                  "Your password has been changed. Please Login again with new Password",
                onOk: () => {
                  this.handleLogout();
                },
              });
              /* window.bootbox.alert(
                "Your password has been changed. Please Login again with new Password",
                (result) => {
                  this.handleLogout();
                }
              );*/
            }
            //check for version and tell user to reload
            if (data.app_version !== this.state.app_version) {
              notification.destroy();
              notification.info({
                message: `Update Available`,
                description:
                  "A new version of Dolphin is available. Please refresh page by pressing (ctrl+f5) to take advantage of new update.",
                placement: "bottomRight",
                duration: null,
              });
            }
            //
            //update essential state variables
            this.setState(
              {
                settings: data.login.settings_allowed,
                user_group: data.user_group,
              },
              function () {
                //set auto answer var
                try {
                  window.sys_settings.auto_answer_incoming =
                    this.state.settings.auto_answer_incoming;
                  window.sys_settings.early_media =
                    this.state.settings.early_media;
                  window.sys_settings.international_dialing =
                    this.state.settings.international_dialing;
                } catch (e) { }
                //
                this.setPAMlabel();
              }
            );
            ///////////////////////////////////////
            this.called_once = true;
            if (
              data.login.num_CallerId !== undefined &&
              data.login.num_CallerId !== null &&
              data.login.num_CallerId !== "null" &&
              data.login.num_CallerId !== ""
            )
              if (data.login.num_CallerId.length > 9)
                if (Number(data.login.num_CallerId) != 0)
                  if (
                    window.wss_cid !== data.login.num_CallerId &&
                    (window.wss_cid === "" || window.wss_cid === undefined)
                  ) {
                    window.wss_cid = data.login.num_CallerId;
                    /*if (window.webphone) {
                      //make sure webphone isn't in call
                      if (!window.webphone.wp_state()) {
                        console.error("Have to re-register");
                        //unregister first
                        window.webphone.wp_unregister();
                        //
                        window.webphone.wp_register();
                      }
                    }*/
                  }
            try {
              window.is_account_active = data.login.client_account_active;
              window.user_account_active =
                data.login.status.toLowerCase() == "active" ? true : false;
            } catch (e) { }
            //we change default timezone thru moment if timezone is not same as before
            if (this.state.timezone !== data.timezone)
              window.moment.tz.setDefault(data.timezone);
            ////////////////////////////////////////////////
          }
          if (data.login.no_CallerId == true && !this.called_once) {
            window.$.notific8("zindex", 11500);
            this.noCallerIdNot = window.$.notific8(
              'No Caller ID is set, please update <a class="font-grey-steel" href="' +
              Aurl +
              '/index.php/Campaigns/Modify/Master">Master Dial Settings</a> to enable dialing.',
              {
                theme: "ruby",
                sticky: true,
                horizontalEdge: "top",
                verticalEdge: "right",
              }
            );
            window.$(".jquery-notific8-close-sticky").on("click", function () {
              window.$.ajax({
                type: "GET",
                xhrFields: {
                  withCredentials: true,
                },
                url: Aurl + "/index.php/Login/end_callerId_not",
                success: function () { },
              });
            });
          }
          if (data.login.are_email_settings_ok == false && !this.called_once) {
            window.$.notific8("zindex", 11500);
            this.noCallerIdNot = $.notific8(
              'Please update <a class="font-grey-steel" href="' +
              Aurl +
              'index.php/Campaigns/Modify/Master">email settings</a> with a "From Address" and valid SMTP Parameters to enable sending emails.',
              {
                theme: "ruby",
                sticky: true,
                horizontalEdge: "top",
                verticalEdge: "right",
              }
            );
            window.$(".jquery-notific8-close-sticky").on("click", function () {
              window.$.ajax({
                type: "GET",
                xhrFields: {
                  withCredentials: true,
                },
                url: Aurl + "index.php/Login/end_email_settings_not",
                success: function () { },
              });
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
    this.cmposX = this.cursorX;
    this.cmposY = this.cursorY;
  };
  _onMouseMove = (e) => {
    window.cursorX = this.cursorX = e.clientX;
    window.cursorY = this.cursorY = e.clientY;
  };
  handleLogout = () => {
    this.setState({ loading: true });
    //do fetch for logout and show login page ;)
    fetch(this.state.baseUrl + "/index.php/Login/Logout", {
      credentials: "include",
    })
      .then((blob) => blob.json())
      .then((data) => {
        this.setState({ isLoggedIn: false });
        this.setState({ loading: false });
        window.refresh_msg = false;
        window.location.href = window.location.origin;
      });
  };

  blockPageUI = (to) => {
    this.setState({ loading: to });
  };
  handleLoginStateChange = (data) => {
    if (data.status) {
      this.setState(
        {
          user_role: data.creds.role,
          user_id: data.creds.user_id,
          client_id: data.creds.client_id,
          user: data.creds.user,
          password: data.creds.password,
          do_register: data.creds.do_register,
          allowed_seats: data.creds.allowed_seats,
          filled_seats: data.creds.filled_seats,
          server_id: data.creds.server_id,
          no_caller_id: data.creds.noCallerId,
          email_settings_ok: data.creds.are_email_settings_ok,
          account_active: data.creds.client_account_active,
          user_account_status: data.creds.status,
          settings: data.creds.settings_allowed,
          timezone: data.creds.timezone,
          user_group: data.creds.user_group,
          login_id: data.creds.login_id,
        },
        function () {
          //set auto answer var
          try {
            window.sys_settings.auto_answer_incoming =
              this.state.settings.auto_answer_incoming;
            window.sys_settings.early_media = this.state.settings.early_media;
            window.sys_settings.international_dialing =
              this.state.settings.international_dialing;
            window.sys_settings.can_play_recordings = this.state.settings.can_play_recordings;
            window.user_role = this.state.user_role;
          } catch (e) { }
          //
          this.setPAMlabel();
          //if client id is -1 then we dont show any menus other than client manager and pbx servers
          if (data.creds.client_id == -1) {
            this.setState({ onlyClientManager: true });
            history.push("/dolphinclients/view");
          }
          //////////////////////////////////////////////////////////////////////////////////////////////
          //we change default timezone thru moment
          window.moment.tz.setDefault(data.creds.timezone);
          ////////////////////////////////////////////////
          this.setState({ isLoggedIn: data.status });

          window.tenant_url = data.creds.tenant.tenant_url;
          window.wss_tenant_url = data.creds.do_register
            ? data.creds.tenant.wss_tenant_url
            : "";
          window.extension = data.creds.user_id;
          window.wss_username = data.creds.user_id;
          window.wss_pass = data.creds.password;
          window.wss_cid = data.creds.num_CallerId;
          window.is_account_active = data.creds.client_account_active;

          try {
            window.user_account_active =
              data.creds.status.toLowerCase() == "active" ? true : false;
            //if (this.state.user_account_status.toLowerCase() == "active")
          } catch (e) { }
        }
      );
      window.dialer_init();
    }
  };
  doPost = (querypath, datab) => {
    this.blockPageUI(true);
    fetch(this.state.baseUrl + querypath, {
      credentials: "include",
      method: "post",
      body: datab,
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        this.blockPageUI(false);
      })
      .catch((e) => {
        this.blockPageUI(false);
      });
  };
  updateAvi = () => {
    this.setState({ imageversion: Math.floor(Math.random() * 100) + 1 });
  };
  getAgreementStatus = () => {
    //temporarily disabling it
    //return false;
    fetch(this.state.baseUrl + "/index.php/Ajax/get_agreement_status", {
      method: "GET",
      credentials: "include",
    })
      .then((data) => data.json())
      .then((dat) => {
        if (
          this.state.user_role == "superadmin" ||
          this.state.user_role == "agent"
        )
          return;
        if (dat.status == true) {
          if (dat.agreement_status !== true) {
            //we set account inactive because agreement isnt signed and they cannot dial
            window.is_account_active = false;
            this.setState({ account_active: false });
            //display a bootbox alert with this info :)
            if (!window.$("#static_agreement_modal").is(":visible")) {
              window.$("#static_agreement_modal").modal("show");
            }
            //////////////////////////////////////////////////
            //display modal based on user id
          } else {
            if (window.$("#static_agreement_modal").is(":visible")) {
              window.$("#static_agreement_modal").modal("hide");
            }
            //here we know agreement is signed, so we'll check if its xd out or not, if not xd out we'll show video :)
            if (dat.getting_started_video_xd_out == false) {
              this.setState({ showGettingStartedModal: true });
            }
            //
          }
        }
      })
      .catch((e) => { });
  };
  //method to add update fly_power_dial list
  update_fly_pd_list = (list) => {
    this.setState({ float_power_dial_list: list }, () => {
      console.log("flying", this.state.float_power_dial_list);
    });
  };
  render() {
    if (!this.state.isLoggedIn)
      return (
        <BlockUi tag="div" blocking={this.state.loading}>
          <Login
            baseUrl={this.state.baseUrl}
            doPost={this.doPost}
            handleLoginStateChange={this.handleLoginStateChange}
          ></Login>
        </BlockUi>
      );
    return (
      <BlockUi
        tag="div"
        onMouseMove={this._onMouseMove}
        blocking={this.state.loading}
      >
        {/* The floating widget to display selected leads for power dialing */}
        <FloatingPdList
          update_fly_pd_list={this.update_fly_pd_list}
          float_power_dial_list={this.state.float_power_dial_list}
        />
        {/****Getting started tutorial */}
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={
            this.state.showGettingStartedModal &&
            !this.state.gettingStartedModalxdOut
          }
          videoId="G6krlZnJGrU"
          onClose={() => {
            this.setState(
              {
                showGettingStartedModal: false,
                gettingStartedModalxdOut: true,
              },
              function () {
                fetch(
                  this.state.baseUrl + "/index.php/Ajax/x_out_getting_started",
                  {
                    method: "GET",
                    credentials: "include",
                  }
                )
                  .then((data) => data.json())
                  .then((data) => { });
              }
            );
          }}
        />
        {/******************** */}
        <HashRouter>
          <WebphoneBar></WebphoneBar>
          <Header
            onlyClientManager={this.state.onlyClientManager}
            username={this.state.user}
            baseUrl={this.state.baseUrl}
            doPost={this.doPost}
            role={this.state.user_role}
            account_active={this.state.account_active}
            settings={this.state.settings}
            user_group={this.state.user_group}
            Logout={this.handleLogout}
            imageversion={this.state.imageversion}
            user_account_active={
              this.state.user_account_status.toLowerCase() == "active"
                ? true
                : false
            }
            history={history}
          ></Header>
          <Switch>
            {[
              "/fromleadimport/:alert_disp?",
              "/fromleadadd/:alert_disp?",
              "/",
            ].map((path, index) => (
              <Route
                key={index}
                path={path}
                render={(props) => (
                  <DashboardSwitcher
                    {...props}
                    role={this.state.user_role}
                    baseUrl={this.state.baseUrl}
                    doPost={this.doPost}
                    blockUI={this.blockPageUI}
                    user_id={this.state.user_id}
                  ></DashboardSwitcher>
                )}
                exact
              />
            ))}
            <Route
              path="/login"
              render={() => (
                <Login
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></Login>
              )}
            />
            <Route
              path="/crm/leadsbylist/:list_id"
              render={(props) => (
                <LeadsList
                  {...props}
                  update_fly_pd_list={this.update_fly_pd_list}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  settings={this.state.settings}
                  role={this.state.user_role}
                  leadview="all"
                ></LeadsList>
              )}
            />
            <Route
              path="/crm/leads/:global_search?"
              render={(props) => (
                <LeadsList
                  {...props}
                  update_fly_pd_list={this.update_fly_pd_list}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  settings={this.state.settings}
                  role={this.state.user_role}
                  leadview="all"
                ></LeadsList>
              )}
            />
            <Route
              path="/crm/search/:global_search"
              render={(props) => (
                <LeadsList
                  {...props}
                  update_fly_pd_list={this.update_fly_pd_list}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  leadview="all"
                  settings={this.state.settings}
                  role={this.state.user_role}
                ></LeadsList>
              )}
            />
            <Route
              path="/crm/contacts"
              render={(props) => (
                <ContactList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  settings={this.state.settings}
                  role={this.state.user_role}
                  contactview="all"
                ></ContactList>
              )}
            />
            <Route
              path="/crm/companies"
              render={() => (
                <CompanyList
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CompanyList>
              )}
            />
            <Route
              path="/crm/editcompany/:company_id"
              render={(props) => (
                <CompanyEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CompanyEdit>
              )}
            />

            <Route
              path="/crm/mycontacts"
              render={(props) => (
                <ContactList
                  {...props}
                  role={this.state.user_role}
                  settings={this.state.settings}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  contactview="my"
                ></ContactList>
              )}
            />
            <Route
              path="/crm/myleads"
              render={(props) => (
                <LeadsList
                  {...props}
                  update_fly_pd_list={this.update_fly_pd_list}
                  role={this.state.user_role}
                  settings={this.state.settings}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  leadview="my"
                ></LeadsList>
              )}
            />
            <Route
              path="/crm/calendar"
              render={() => (
                <Calendar
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></Calendar>
              )}
            />
            <Route
              path="/leads/view/:lead_id"
              render={(props) => (
                <ViewSingleLead
                  {...props}
                  settings={this.state.settings}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                ></ViewSingleLead>
              )}
            />
            <Route
              path="/contacts/view/:lead_id"
              render={(props) => (
                <ViewSingleContact
                  {...props}
                  settings={this.state.settings}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ViewSingleContact>
              )}
            />
            <Route
              path="/addnew/campaign"
              render={() => (
                <CampaignAdd
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CampaignAdd>
              )}
            />
            <Route
              path="/admin/campaignedit/:campaign_id/:alert_disp?"
              render={(props) => (
                <CampaignEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CampaignEdit>
              )}
            />
            <Route
              path="/addnew/company"
              render={() => (
                <CompanyAdd
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CompanyAdd>
              )}
            />
            <Route
              path="/addnew/contact"
              render={() => (
                <ContactAdd
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ContactAdd>
              )}
            />
            <Route
              path={["/addnew/lead", "/addnew/leadnew/:phone_number/:lead_id"]}
              render={(props) => (
                <LeadAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></LeadAdd>
              )}
            />
            <Route
              path="/addnew/importleads"
              render={(props) => (
                <LeadImport
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></LeadImport>
              )}
            />
            <Route
              path="/addnew/list"
              render={() => (
                <ListAdd
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ListAdd>
              )}
            />
            <Route
              path="/reports/realtime"
              render={(props) => (
                <ReportRealTime
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                  user_id={this.state.user_id}
                ></ReportRealTime>
              )}
            />
            <Route
              path="/reports/exportleads"
              render={(props) => (
                <ReportLeadsExport
                  user_role={this.state.user_role}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ReportLeadsExport>
              )}
            />
            <Route
              path="/reports/agentperformancedetail"
              render={(props) => (
                <ReportAgentPerformanceDetail
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                  settings={this.state.settings}
                ></ReportAgentPerformanceDetail>
              )}
            />
            <Route
              path="/reports/outboundcalling"
              render={(props) => (
                <ReportOutboundCalling
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                ></ReportOutboundCalling>
              )}
            />
            <Route
              path="/reports/cdr"
              render={(props) => (
                <ReportCDR
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                ></ReportCDR>
              )}
            />
            <Route
              path="/reports/cdr_new"
              render={(props) => (
                <ReportCDR_n
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                ></ReportCDR_n>
              )}
            />
            <Route
              path="/reports/smsdr"
              render={(props) => (
                <TextDetails
                  settings={this.state.settings}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                ></TextDetails>
              )}
            />
            <Route
              path="/reports/extsummary"
              render={(props) => (
                <ReportExtensionSummary
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                  settings={this.state.settings}
                ></ReportExtensionSummary>
              )}
            />
            <Route
              path="/reports/adminlog"
              render={(props) => (
                <ReportAdminLog
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ReportAdminLog>
              )}
            />

            <Route
              path="/admin/masterdialsettings"
              render={(props) => (
                <MasterDialSettings
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                ></MasterDialSettings>
              )}
            />
            <Route
              path="/admin/configurations"
              render={(props) => (
                <Configurations
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                  settings={this.state.settings}
                ></Configurations>
              )}
            />
            <Route
              path="/admin/syssettings"
              render={(props) => (
                <SystemSettings
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_role={this.state.user_role}
                  settings={this.state.settings}
                ></SystemSettings>
              )}
            />
            <Route
              path="/admin/dispoworkflows/view/:alert_disp?"
              render={(props) => (
                <WorkflowList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></WorkflowList>
              )}
            />
            <Route
              path="/admin/dispoworkflows/add"
              render={(props) => (
                <WorkflowAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></WorkflowAdd>
              )}
            />
            <Route
              path="/admin/dispoworkflows/edit/:workflow_id"
              render={(props) => (
                <WorkflowEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></WorkflowEdit>
              )}
            />
            <Route
              path="/admin/customfields/:show_alert?"
              render={(props) => (
                <CustomFields
                  {...props}
                  user_role={this.state.user_role}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CustomFields>
              )}
            />
            <Route
              path="/admin/customfieldsedit/:cf_edit_id"
              render={(props) => (
                <CustomFields_edit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CustomFields_edit>
              )}
            />
            <Route
              path="/admin/sysfieldedit/:sf_edit_id"
              render={(props) => (
                <SystemFields_edit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></SystemFields_edit>
              )}
            />
            <Route
              path="/admin/users/view/:alert_disp?"
              render={(props) => (
                <UserList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></UserList>
              )}
            />
            <Route
              path={"/admin/users/add"}
              render={(props) => (
                <UserAdd
                  {...props}
                  role={this.state.user_role}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></UserAdd>
              )}
            />
            <Route
              path="/admin/useredit/:user_id"
              render={(props) => (
                <UserEdit
                  {...props}
                  role={this.state.user_role}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></UserEdit>
              )}
            />
            {/*********************** */}
            <Route
              path="/admin/usergroups/view/:alert_disp?"
              render={(props) => (
                <UserGroupList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></UserGroupList>
              )}
            />
            <Route
              path={"/admin/usergroups/add"}
              render={(props) => (
                <UserGroupAdd
                  {...props}
                  role={this.state.user_role}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></UserGroupAdd>
              )}
            />
            <Route
              path="/admin/usergroups/edit/:usergroup"
              render={(props) => (
                <UserGroupEdit
                  {...props}
                  role={this.state.user_role}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></UserGroupEdit>
              )}
            />
            {/******************************** */}
            <Route
              path="/admin/campaigns/view"
              render={(props) => (
                <CampaignList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CampaignList>
              )}
            />
            <Route
              path="/admin/campaigns/add"
              render={(props) => (
                <CampaignAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></CampaignAdd>
              )}
            />
            <Route
              path="/admin/lists/view"
              render={(props) => (
                <ListsList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  settings={this.state.settings}
                  user_role={this.state.user_role}
                ></ListsList>
              )}
            />
            <Route
              path="/admin/listedit/:list_id/:alert_disp?"
              render={(props) => (
                <ListEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ListEdit>
              )}
            />
            <Route
              path="/admin/lists/DNC"
              render={(props) => (
                <ListDNC
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ListDNC>
              )}
            />
            <Route
              path="/admin/lists/add"
              render={(props) => (
                <ListAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ListAdd>
              )}
            />
            <Route
              path="/admin/inbound/did_add"
              render={(props) => (
                <DIDadd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></DIDadd>
              )}
            />
            <Route
              path="/admin/inbound/did_list"
              render={(props) => (
                <DIDlist
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_id={this.state.user_id}
                ></DIDlist>
              )}
            />
            <Route
              path="/admin/inbound/ringgroup_add"
              render={(props) => (
                <RingGroupAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></RingGroupAdd>
              )}
            />
            <Route
              path={["/admin/scripts/view", "/admin/scriptsview/:success"]}
              render={(props) => (
                <ScriptList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ScriptList>
              )}
            />
            <Route
              path="/admin/scripts/add"
              render={(props) => (
                <ScriptAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ScriptAdd>
              )}
            />
            <Route
              path="/admin/scriptedit/:script_id"
              render={(props) => (
                <ScriptEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ScriptEdit>
              )}
            />
            <Route
              path="/admin/emailtemplates/view"
              render={(props) => (
                <EmailTemplateList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></EmailTemplateList>
              )}
            />
            <Route
              path="/admin/emailtemplates/add"
              render={(props) => (
                <EmailTemplateAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></EmailTemplateAdd>
              )}
            />
            <Route
              path="/admin/emailtemplateedit/:template_id"
              render={(props) => (
                <EmailTemplateEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></EmailTemplateEdit>
              )}
            />
            <Route
              path="/admin/emailtemplates/settings"
              render={(props) => (
                <EmailTemplateSettings
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></EmailTemplateSettings>
              )}
            />

            <Route
              path="/myemailconfig"
              render={(props) => (
                <UsersEmailConfig
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></UsersEmailConfig>
              )}
            />
            {/************************* Text Templates */}
            <Route
              path="/admin/texttemplates/view/:alert_disp?"
              render={(props) => (
                <TextTemplates
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></TextTemplates>
              )}
            />
            <Route
              path="/admin/texttemplates/add"
              render={(props) => (
                <TextTemplateAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></TextTemplateAdd>
              )}
            />
            <Route
              path="/admin/texttemplateedit/:template_id"
              render={(props) => (
                <TextTemplateEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></TextTemplateEdit>
              )}
            />
            <Route
              path="/admin/texttemplates/settings"
              render={(props) => (
                <TextDetails
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></TextDetails>
              )}
            />
            {/******************************** */}
            <Route
              path="/admin/recordings/add"
              render={(props) => (
                <RecordingAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></RecordingAdd>
              )}
            />
            <Route
              path="/admin/recordings/view/:alert_disp?"
              render={(props) => (
                <RecordingList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  settings={this.state.settings}
                ></RecordingList>
              )}
            />
            {/*********************Stuff for testing only 
            <Route
              path="/test/leadslist/:global_search?"
              render={(props) => (
                <LeadList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  settings={this.state.settings}
                ></LeadList>
              )}
            />

             */}
            <Route
              path="/pbxservers/view"
              render={(props) => (
                <ServerList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ServerList>
              )}
            />
            <Route
              path="/pbxservers/addserver"
              render={(props) => (
                <ServerAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ServerAdd>
              )}
            />
            <Route
              path="/pbxservers/editserver/:server_id"
              render={(props) => (
                <ServerEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></ServerEdit>
              )}
            />
            <Route
              path="/pbxservers/addtenant/:server_id"
              render={(props) => (
                <TenantAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></TenantAdd>
              )}
            />
            <Route
              path="/pbxservers/edittenant/:tenant_id"
              render={(props) => (
                <TenantEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></TenantEdit>
              )}
            />
            <Route
              path="/dolphinclients/view"
              render={(props) => (
                <DolphinClientList
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  user_id={this.state.user_id}
                ></DolphinClientList>
              )}
            />
            <Route
              path="/dolphinclients/add"
              render={(props) => (
                <DolphinClientAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></DolphinClientAdd>
              )}
            />
            <Route
              path="/dolphinclients/edit/:client_id"
              render={(props) => (
                <DolphinClientEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></DolphinClientEdit>
              )}
            />
            <Route
              path="/dolphinclients/calleridswhitelist/:client_id"
              render={(props) => (
                <WhitelistedCids
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></WhitelistedCids>
              )}
            />
            <Route
              path="/myprofile"
              render={(props) => (
                <MyProfile
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                  imageversion={this.state.imageversion}
                  onupdateAvi={this.updateAvi}
                  handleLogout={this.handleLogout}
                ></MyProfile>
              )}
            />
            <Route
              path="/mypam"
              render={(props) => (
                <MyPam
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></MyPam>
              )}
            />
            <Route
              path="/mycallbacks"
              render={(props) => (
                <MyCallBacks
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></MyCallBacks>
              )}
            />
            <Route
              path="/leadscallbacks/:lead_id"
              render={(props) => (
                <LeadsCallBacks
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></LeadsCallBacks>
              )}
            />
            {/***Leadevents crud next pages */}
            <Route
              path="/leadeventedit/:event_id"
              render={(props) => (
                <LeadEventEdit
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></LeadEventEdit>
              )}
            />
            <Route
              path="/leadeventadd/"
              render={(props) => (
                <LeadEventAdd
                  {...props}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></LeadEventAdd>
              )}
            />
            {/***leadevents crud above */}
            <Route
              path="/mystats"
              render={(props) => (
                <MyPerfReport
                  {...props}
                  role={this.state.user_role}
                  settings={this.state.settings}
                  baseUrl={this.state.baseUrl}
                  doPost={this.doPost}
                  blockUI={this.blockPageUI}
                ></MyPerfReport>
              )}
            />
          </Switch>
          <Footer
            role={this.state.user_role}
            baseUrl={this.state.baseUrl}
            doPost={this.doPost}
            client_id={this.state.client_id}
            user_id={this.state.user_id}
          ></Footer>
        </HashRouter>
      </BlockUi>
    );
  }
}

export default App;
